<template>
  <div v-show="isMobile && isLogin" class="dropdown_user" style="padding-right: 16px; padding-left: 16px; margin-bottom: 0">
    <SvgIcon icon-class="user" class-name="dropdown_user--icon" />
    <div class="flex_column">
        <span class="dropdown_user--identity">{{
            $t(`account_role.type[${Number(role)}]`)
          }}</span>
      <span class="dropdown_user--text"
      >{{ $t("navbar.hello") }}
          {{ name?.length > 0 ? name : $t("navbar.member") }}</span
      >
    </div>
  </div>
  <div class="menu_dropdown">
    <div v-if="!isLogin" class="menu_dropdown--header" @click="pressRegister">
      <div class="menu_dropdown--header--textView">
        <h4>{{ $t('navbar.register') }}</h4>
      </div>
    </div>
  </div>
  <div v-if="!isLogin" class="menu_dropdown" @click="pressLogin">
    <div class="menu_dropdown--header">
      <div class="menu_dropdown--header--textView">
        <h4>{{ $t('navbar.login') }}</h4>
      </div>
    </div>
  </div>
  <div v-if="isLogin" class="menu_dropdown" @click="pressMember">
    <div class="menu_dropdown--header">
      <div class="menu_dropdown--header--textView">
        <h4>{{ $t('navbar.memberCenter') }}</h4>
      </div>
    </div>
  </div>
  <div v-if="isLogin" class="menu_dropdown" @click="pressIdentity">
    <div class="menu_dropdown--header">
      <div class="menu_dropdown--header--textView">
        <h4>{{ $t('memberCenter.switchIdentity') }}</h4>
      </div>
    </div>
  </div>
  <div class="menu_dropdown" :class="{ 'choose': open1 }" @click="() => open1 = !open1">
    <div class="menu_dropdown--header">
      <div class="menu_dropdown--header--textView">
        <h4>{{ $t('navbar.objectType') }}</h4>
      </div>
      <SvgIcon icon-class="arrow_down" />
    </div>
    <ul v-if="open1" class="menu_list">
      <li
          v-for="(item, index) in menuOption"
          :key="index"
          class="menu_option"
          :class="{ activate: $route.name === item.route || $route.name === item.match || $route.name === item.match2  }"
          @click.stop="directPath(item.route)"
      >
        {{ item.name }}
      </li>
    </ul>
  </div>
  <div class="menu_dropdown" :class="{ 'choose': open2 }" @click="() => open2 = !open2">
    <div class="menu_dropdown--header">
      <div class="menu_dropdown--header--textView">
        <h4>{{ $t('footer.resource') }}</h4>
      </div>
      <SvgIcon icon-class="arrow_down" />
    </div>
    <ul v-if="open2" class="menu_list">
      <li
          v-for="(item, index) in resourceList"
          :key="index"
          class="menu_option"
          :class="{ activate: $route.name.indexOf(item.route) !== -1 }"
          @click.stop="chooseOption(item.name)"
      >
        {{ item.name }}
      </li>
    </ul>
  </div>
  <div class="menu_dropdown">
    <div class="menu_dropdown--header" :style="{ 'borderColor': !isLogin ? 'transparent' : '' }" @click="pressForum">
      <div class="menu_dropdown--header--textView">
        <h4>{{ $t('navbar.forum') }}</h4>
      </div>
    </div>
  </div>
  <div v-if="isLogin" class="menu_dropdown" @click="pressSignOut">
    <div class="menu_dropdown--header" :style="{ 'borderColor': isLogin ? 'transparent' : '' }">
      <div class="menu_dropdown--header--textView">
        <h4 class="alert">{{ $t('navbar.signOut') }}</h4>
      </div>
    </div>
  </div>
  <div class="menu_dropdown">
    <div class="navbar_items--outline">
      <div class="navbar_items--outline--section">
        <button type="button" class="navbar_items--video" @click="directToVideo">
          <SvgIcon icon-class="play_circle_fill" class-name="navbar_items--video--icon" />
          <span>
            {{ $t('navbar.video') }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { logout } from '@/plugins/api/auth/auth';
import SwitchIdentity from '@/views/memberCenter/page/common/home/components/SwitchIdentity.vue';

export default {
  name: 'MobileUserContent',
  components: {
    SwitchIdentity,
  },
  props: {
  },
  data() {
    return {
      menuOption: [
        {
          name: this.$t('navbar.rent'),
          route: 'rent',
          match: 'rentDetail',
        },
        {
          name: this.$t('navbar.sale'),
          route: 'sale',
          match: 'saleDetail',
        },
        {
          name: this.$t('navbar.preSale'),
          route: 'preSale',
          match: 'preSaleDetail',
        },
        {
          name: this.$t('navbar.factory'),
          route: 'factory',
          match: 'factoryRentDetail',
          match2: 'factorySaleDetail',
        },
        {
          name: this.$t('navbar.land'),
          route: 'land',
          match: 'landRentDetail',
          match2: 'landSaleDetail',
        },
        {
          name: this.$t('navbar.office'),
          route: 'office',
          match: 'officeRentDetail',
          match2: 'officeSaleDetail',
        },
        {
          name: this.$t('navbar.shop'),
          route: 'shop',
          match: 'shopRentDetail',
          match2: 'shopSaleDetail',
        },
      ],
      resourceList: [
        {
          name: this.$t('footer.mortgageCalculator'),
          route: 'mortgageCalculator',
        },
        {
          name: this.$t('files.download'),
          route: 'files',
        },
      ],
      openSwitch: false,
      open1: false,
      open2: false,
    };
  },
  watch: {
    lang(value, oldValue) {
      if (value !== oldValue) {
        this.menuOption = [
          {
            name: this.$t('navbar.rent'),
            route: 'rent',
            match: 'rentDetail',
          },
          {
            name: this.$t('navbar.sale'),
            route: 'sale',
            match: 'saleDetail',
          },
          {
            name: this.$t('navbar.preSale'),
            route: 'preSale',
            match: 'preSaleDetail',
          },
          {
            name: this.$t('navbar.factory'),
            route: 'factory',
            match: 'factoryRentDetail',
            match2: 'factorySaleDetail',
          },
          {
            name: this.$t('navbar.land'),
            route: 'land',
            match: 'landRentDetail',
            match2: 'landSaleDetail',
          },
          {
            name: this.$t('navbar.office'),
            route: 'office',
            match: 'officeRentDetail',
            match2: 'officeSaleDetail',
          },
          {
            name: this.$t('navbar.shop'),
            route: 'shop',
            match: 'shopRentDetail',
            match2: 'shopSaleDetail',
          },
        ];
        this.resourceList = [
          {
            name: this.$t('footer.mortgageCalculator'),
            route: 'mortgageCalculator',
          },
          {
            name: this.$t('files.download'),
            route: 'files',
          },
        ];
      }
    },
  },
  computed: {
    ...mapGetters(['lang', 'isLogin', 'isMobile', 'identity', 'name', 'role', 'isSub']),
  },
  emits: ['close'],
  methods: {
    pressRegister() {
      this.$router.push({ name: 'identity' });
      this.$emit('close');
    },
    pressLogin() {
      this.$router.push({ name: 'login' });
      this.$emit('close');
    },
    pressMember() {
      if (this.identity === undefined) {
        this.$router.push({ name: 'guestHome' });
      } else {
        this.$router.push({ name: `${this.identity}Home` });
      }
      this.$emit('close');
    },
    pressIdentity() {
      this.$store.commit('UPDATE_SWITCH_IDENTITY', true);
    },
    pressForum() {
      this.$router.push({ name: 'forumAllArticlesOnHome' });
      this.$emit('close');
    },
    directPath(name) {
      if (name === 'rent' || name === 'sale' || name === 'preSale' || name === 'firstPublish') {
        this.$router.push({ name });
      } else if (name === 'factory') {
        this.$router.push({
          name,
          query: {
            type: 4,
          },
        });
      } else if (name === 'land') {
        this.$router.push({
          name,
          query: {
            type: 6,
          },
        });
      } else if (name === 'office') {
        this.$router.push({
          name,
          query: {
            type: 8,
          },
        });
      } else if (name === 'shop') {
        this.$router.push({
          name,
          query: {
            type: 10,
          },
        });
      }
      this.$emit('close');
    },
    chooseOption(value) {
      switch (value) {
        case this.$t('footer.mortgageCalculator'):
          this.$router.push({ name: 'mortgageCalculator' });
          break;
        case this.$t('files.download'):
          this.$router.push({ name: 'files' });
          break;
        default:
          break;
      }
      this.$emit('close');
    },
    pressSignOut() {
      logout().then((res) => {
        if (res.success) {
          this.$store.dispatch('logOut');
          this.$toast.success(this.$t('noticeText.logoutSuccess'));
          this.$emit('close');
          if (this.$route.meta.requiredAuth) {
            this.$router.push({ name: 'home' });
          }
        } else {
          this.$toast.error(this.$t('alertText.logoutFail'));
        }
      }).catch(() => {
        this.$toast.error(this.$t('alertText.logoutFail'));
      });
    },
    directToVideo() {
      this.$emit('close');
      this.$router.push({ name: 'video' });
    },
    clickOtherElement(e) {
      const userStatus = document.querySelector('#user');
      if (!userStatus.contains(e.target)) {
        this.$emit('close');
      }
    },
  },
};
</script>
