<template>
  <input
      type="text"
      class="custom_input"
      :placeholder="placeholder"
      :id="id"
      aria-label="input"
      :class="{ 'disabled': disabled }"
      :disabled="disabled ? disabled : false"
      :readonly="readyonly ? readyonly : false"
      :value="type === 'number' ? formatterValue : value"
      :inputmode="type === 'number' ? 'numeric' : 'text'"
      :min="min"
      :max="max"
      @input="changeValue($event, index)"
      @compositionstart="flag = true"
      @compositionend="changeValue2($event)"
  />
</template>

<script>
export default {
  name: 'DefaultInput',
  props: {
    placeholder: {
      type: String,
    },
    id: {
      type: String,
    },
    type: {
      type: String,
      default: () => 'text',
    },
    disabled: {
      type: Boolean,
    },
    readyonly: {
      type: Boolean,
    },
    min: {
      type: Number,
      default: () => 0,
    },
    max: {
      type: Number,
    },
    value: [String, Number],
    index: {
      type: Number,
    },
  },
  emits: ['changeValue'],
  data() {
    return {
      flag: false,
    };
  },
  computed: {
    formatterValue() {
      if (this.value !== null && !isNaN(this.value)) {
        return this.formatValue(this.value);
      }
      return this.value;
    },
  },
  methods: {
    formatValue(num) {
      return Number(num)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    parseValue(text) {
      // 去除千分位
      return Number(text.replace(/,/g, ''));
    },
    changeValue2(event) {
      this.flag = false;
      this.changeValue(event);
    },
    changeValue(event, index) {
      if (this.flag) return;
      const input = event.target;
      if (this.type === 'number') {
        const halfWidthValue = input.value.replace(/[\uFF10-\uFF19\uFF0E\uFF0D]/g, (char) => String.fromCharCode(char.charCodeAt(0) - 0xFEE0));
        const sanitizedValue = halfWidthValue.replace(/[^0-9]/g, ''); // 確保負號只出現在開頭
        const parts = sanitizedValue.split('.');

        // 若有多個小數點，只保留第一個
        if (parts.length > 2) {
          input.value = `${parts[0]}.${parts.slice(1).join('')}`;
          return;
        }

        // 格式化數字（加上逗號）
        const integerPart = parts[0].replace(/,/g, ''); // 移除舊的逗號
        const formattedInteger = Number(integerPart).toString(); // 格式化整數部分
        input.value = parts.length > 1 ? `${formattedInteger}.${parts[1]}` : formattedInteger;
        this.$emit('changeValue', this.parseValue(input.value), index);
      } else {
        this.$emit('changeValue', input.value, index);
      }
    },
  },
};
</script>
