const vn = {
  title: 'Trang web tìm nhà 88go house',
  description: 'Tìm nhà của bạn tại 88go!',
  breadcrumbList: {
    home: 'Trang chủ',
    rent: 'Thuê nhà',
    sale: 'Mua bán',
    preSale: 'Dự án mới',
    factory: 'Nhà xưởng',
    land: 'Đất',
    mapSearch: ' Bản đồ ',
    choose88go: 'Vì sao chọn 88go',
    ourPlan: 'Gói dịch vụ của chúng tôi',
    upgradeAccount: 'Kế hoạch nâng cấp tài khoản',
    agent: 'Điều lệ đại diện',
    disclaimer: 'Tuyên bố miễn trừ trách nhiệm',
    policy: 'Điều khoản và quyền riêng tư',
    publish: 'Đăng tin',
    memberHome: 'Trang chủ thành viên',
    account: 'Tài khoản',
    changePhone: 'Thay đổi số điện thoại',
    changePwd: 'Thay đổi mật khẩu',
    message: 'Quản lý tin nhắn',
    messageContent: 'Nội dung tin nhắn',
    houseQA: 'Hỏi đáp nhà ở',
    impeach: 'Báo cáo',
    history: 'Lịch sử',
    favoriteObjects: 'Danh mục yêu thích',
    transactionRecords: 'Hồ sơ giao dịch',
    promotions: 'Sự kiện quảng bá',
    activityDetails: 'Chi tiết sự kiện',
    performanceReport: 'Báo cáo hiệu suất',
    accountManagement: 'Quản lý tài khoản',
    addAccount: 'Thêm tài khoản mới',
    transaction: 'Quản lý giao dịch',
    plansManagement: 'Quản lý gói dịch vụ',
    upgradeManagement: 'Quản lý nâng cấp',
    cashManagement: 'Quản lý dòng tiền',
    purchasePlan: 'Mua gói dịch vụ',
    paymentManagement: 'Quản lý thanh toán',
    addNewCard: 'Thêm thẻ mới',
    publishManagement: 'Danh sách tin',
    housesClosed: 'Đã đóng',
    housesEdit: 'Không có danh sách',
    removedHouses: 'Đã giao dịch',
    renew: 'Thay mới',
    singlePosting: 'Đăng tin đơn',
    rentalPlanPublication: 'Gói đăng tin cho thuê',
    salePlanPublication: 'Gói đăng tin mua bán',
    editListing: 'Chỉnh sửa tin',
    improveEffect: 'Cải thiện hiệu quả',
    storeManagement: 'Quản lý cửa hàng',
    storeQA: 'Hỏi đáp về cửa hàng',
    flyers: 'Tạo tờ rơi',
    improve: 'Cải thiện hiệu ứng',
    certification: 'Chứng nhận',
    workData: 'Tài liệu',
    subAccount: 'Quản lý tài khoản phụ',
    advertisement: 'Quản lý quảng cáo',
    applyForAds: 'Đăng ký quảng cáo',
    forum: 'Nền tảng thảo luận',
    myArticles: 'Bài báo của tôi',
    publishArticle: 'Đăng một bài báo',
    editArticle: 'Biên tập',
    myRequirements: 'Nhu cầu',
    advertisementApplicationRecord: 'Kiểm duyệt quảng cáo',
    video: 'Videos',
    businessManagement: 'Quản lý kinh doanh',
    businessNotification: 'Kinh doanh',
    tutorial: '使用教学',
  },
  navbar: {
    publish: 'Đăng tin',
    rent: 'Thuê nhà',
    sale: 'Mua bán',
    preSale: 'Dự án mới',
    factory: 'Nhà xưởng',
    land: 'Đất',
    house: 'Nhà ở',
    office: 'Văn phòng',
    shop: 'Cửa hàng',
    register: 'Đăng ký',
    login: 'Đăng nhập',
    memberCenter: 'Trung tâm thành viên',
    signOut: 'Đăng xuất',
    hello: 'Xin chào',
    member: 'Thành viên',
    forum: 'Nền tảng thảo luận',
    video: 'Videos',
    join: 'Tài khoản',
    objectType: 'Loại đối tượng',
  },
  login: {
    phoneNumber: 'Số điện thoại',
    phonePlaceholder: 'Vui lòng nhập số điện thoại',
    pwd: 'Mật khẩu',
    pwdplaceholder: 'Vui lòng nhập mật khẩu từ 8 đến 12 ký tự bao gồm các ký tự chữ và số',
    remember: 'Ghi nhớ đăng nhập',
    forget: 'Quên mật khẩu',
    login: 'Đăng nhập',
    noAccount: 'Chưa có tài khoản?',
    register: 'Đăng ký',
    accountLogin: 'Đăng nhập bằng tài khoản xã hội',
    socialAccountNotice: 'Đăng nhập tài khoản xã hội cần phải được liên kết với tài khoản thành viên trước khi có thể sử dụng.',
  },
  register: {
    registrationStatus: 'Vui lòng chọn hình thức đăng ký của bạn',
    buyer: 'Người mua/Người thuê',
    choose: 'Chọn',
    owner: 'Chủ sở hữu/đại diện',
    estateAgent: 'Người quản lý/Môi giới',
    estateAgency: 'Công ty môi giới',
    social: 'Đăng ký bằng tài khoản xã hội',
    hostWarningText: 'Nhân viên môi giới pháp lý không được phép đăng ký bằng danh tính (Chủ sở hữu/người đại diện)',
    agentWarningText: 'Vui lòng cho biết tên ngành môi giới. Nhân viên môi giới không hợp pháp không được phép kinh doanh trên 88go',
    emergencyText: 'Trong trường hợp tài khoản có vấn đề/xác nhận tin nhắn/hoạt động của hệ thống vui lòng liên hệ tông đài hỗ trợ',
    hasAccount: 'Bạn đã có tài khoản?',
    login: 'Đăng nhập',
    phoneNumber: 'Số điện thoại',
    phonePlaceholder: 'Vui lòng nhập số điện thoại',
    pwd: 'Mật khẩu',
    pwdplaceholder: 'Vui lòng nhập mật khẩu từ 8 đến 12 ký tự bao gồm các ký tự chữ và số',
    confirmPwd: 'Xác nhận mật khẩu',
    name: 'Họ tên',
    nameplaceholder: 'Vui lòng nhập họ tên',
    emailplaceholder: 'Vui lòng nhập Email',
    gender: 'Giới tính',
    male: 'Nam',
    female: 'Nữ',
    ready: 'Tôi đã đọc và hiểu',
    terms: 'Điều khoản và quyền riêng tư.',
    and: 'Và',
    disclaimer: 'Tuyên bố miễn trừ trách nhiệm',
    ready2: 'Chúng tôi đồng ý với nội dung và các điều khoản này và sẵn sàng tuân thủ các quy tắc khác nhau của trang web cũng như các quy định tiếp theo',
    register: 'Đăng ký',
    accountOwnership: 'Sở hữu tài khoản',
    companyAccount: 'Tài khoản công ty',
    branchAccount: 'Tài khoản chi nhánh',
    other: 'Khác',
    workArea: 'Khu vực làm việc',
    company: 'Công ty',
    branch: 'Chi nhánh',
    position: 'Chức vụ',
    brokerageName: 'Tên môi giới',
    brokerageNameplaceholder: 'Vui lòng nhập tên môi giới',
    storeType: 'Hình thức cửa hàng',
    directSale: 'Cửa hàng trực tiếp',
    franchise: 'Cửa hàng nhượng quyền',
    invoice: 'Hóa đơn',
    invoiceType: {
      donate: 'Cơ chế quyên góp',
      donateAlert: 'Không thể thực hiện thêm quyên góp',
      donateDes: 'Tổ chức nhận:Quỹ giáo dục',
      send: 'Gửi về trung tâm',
      sendDes: '(Quy trình đổi giải thưởng sẽ được thực hiện vào ngày 26 hàng tháng. Nếu bạn thắng, bạn sẽ được thông báo bằng SMS và gửi bằng thư bảo đảm)',
      tax: 'Số seri',
      taxAlert: 'Hãy điền cẩn thận tránh bị thất lạc',
      taxDes: '(Gửi bằng thư thường, thời gian gửi sẽ lâu hơn)',
      receipt: 'Hóa đơn điện tử/nhà mạng điện thoại di động',
      receiptDes: '(Được ban hành dựa trên “Những điểm chính cho hoạt động thử nghiệm phát hành hóa đơn điện tử qua kênh tiêu dùng”)',
      buyer: 'Người mua',
      buyerplaceholder: 'Vui lòng nhập họ tên',
      contactPhone: 'Số điện thoại liên lạc',
      contactPhonePlaceholder: 'Vui lòng nhập số liên lạc',
      addressplaceholder: 'Vui lòng nhập địa chỉ nhận thư đầy đủ của bạn',
      taxplaceholder: 'Vui lòng nhập mã số',
      mobileBarcode: 'Mã của nhà mạng điện thoại',
      mobileBarcodeplaceholder: 'Vui lòng nhập mã điện thoại',
      confirmMobileBarcode: 'Xác nhận mã điện thoại',
    },
    next: 'Tiếp tục',
    emergencyIdentity: 'Người liên lạc khẩn cấp',
    emergencyIdentityplaceholder: 'Vui lòng chọn người liên lạc khẩn cấp',
    emergencyName: 'Họ tên',
    emergencyNameplaceholder: 'Vui lòng nhập họ tên',
    emergencyNumber: 'Số điện thoại liên lạc khẩn cấp',
    notice: 'Đề phòng:<br>'
            + '1.Người liên lạc khẩn cấp chỉ là kênh thông báo cho thành viên và không thể thay mặt thành viên thực hiện quyền tài khoản.<br>'
            + '2.Thông báo cho người liên lạc khẩn cấp được coi là đã thông báo cho thành viên nên nếu thông tin liên lạc thay đổi thì phải cập nhật ngay lập tức.'
            + ' Nếu người liên lạc khẩn cấp từ chối trả lời,'
            + ' không hỗ trợ thông báo cho thành viên hoặc không liên lạc được,… dẫn đến thành viên không nhận được thông báo,thành viên sẽ hoàn toàn chịu trách nhiệm.',
  },
  fogotPwd: {
    enterPhone: 'Vui lòng nhập số điện thoại di động của bạn dưới đây',
    phoneNumber: 'Số điện thoại',
    phonePlaceholder: 'Vui lòng nhập số điện thoại',
    next: 'Tiếp tục',
  },
  verify: {
    sendCode: 'Mã xác minh gồm 4 chữ số đã được gửi tới',
    verify: 'Xác minh',
    notReceive: 'Không nhận được mã xác minh?',
    resendCode: 'Gửi lại mã',
    verifySuccess: 'Xác minh điện thoại di động thành công, bạn sẽ được chuyển hướng đến trang thay đổi mật khẩu',
    confirm: 'Xác nhận',
    thxToRegister: 'Cảm ơn bạn đã đăng ký làm thành viên 88go. Số điện thoại di động của bạn đã được xác minh thành công',
    socialMedia: 'Bạn có muốn vào trung tâm thành viên để đăng nhập nhanh và liên kết tài khoản mạng xã hội của mình không?',
    loginAgain: 'Đăng nhập lại',
    goFillIn: 'Vào điền thông tin cá nhân',
    goHome: 'Không, về trang chủ',
  },
  home: {
    slogan: 'Để chúng tôi tìm kiếm',
    idelHome: 'Ngôi nhà lí tưởng',
    saleSlogan: 'Thực hiện',
    homeDream: 'Ngôi nhà mơ ước',
    saleSlogan2: 'Bắt đầu từ đây',
    newHouseSlogan: 'Tận hưởng một cuộc sống mới và chào đón',
    newHouseSlogan2: 'tương lai tươi sáng!',
    keywordPlaceholder: 'Vui lòng nhập từ khóa',
    area: 'Khu vực',
    houseType: 'Loại hình nhà ở',
    housingLayout: 'Bố cục nhà ở',
    sort: 'Lọc',
    preferredHouse: 'Ưa thích',
    preferredConstruction: 'Ưa thích',
    preferredFactory: 'Ưa thích',
    preferredLand: 'Ưa thích',
    preferredOffice: 'Ưa thích',
    preferredShop: 'Ưa thích',
    suitable: 'Phù hợp cho bạn',
    suitableNewHouse: 'Thích hợp cho dự án mới của bạn',
    suitableFactory: 'Thích hợp cho nhà máy của bạn',
    suitableLand: 'Mảnh đất phù hợp cho bạn',
    suitableOffice: 'Phù hợp cho văn phòng của bạn',
    suitableShop: 'Thích hợp cho cửa hàng của bạn',
    more: 'Xem thêm',
    rentInPopular: 'Thuê nhà khu vực phổ biến',
    buyingPopular: 'Mua bán khu vực phổ biến',
    newHousePopular: 'Trường hợp khu vực phổ biến',
    factoryPopular: 'Nhà xưởng ở những khu vực phổ biến',
    landPopular: 'Đất khu vực phổ biến',
    officePopular: 'Văn phòng tại các khu vực phổ biến',
    shopPopular: 'Các cửa hàng khu vực phổ biến',
    listSearch: 'Danh sách',
    mapSearch: 'Bản đồ',
    news: 'Tin tức truyền thông',
    learnMoreAbout88go: 'Tìm hiểu về 88go',
    // eslint-disable-next-line max-len
    aboutDes: 'Chúng tôi là nền tảng cung cấp đầy đủ các giải pháp nhà ở cho dù nhu cầu của bạn là gì, 88go sẽ là đối tác đáng tin cậy của bạn.  Chúng tôi mong muốn được cung cấp cho bạn dịch vụ tốt nhất trong quá trình lưu trú của bạn.  Đồng hành cùng bạn để nhận ra một tương lai mới của cuộc sống.',
    multipleListing: 'Nhiều tư liệu nhà ở',
    listingDes: 'Sở hữu nhiều bất động sản chất lượng cao đáp ứng nhiều nhu cầu và ngân sách khác nhau',
    noFee: 'Không mất phí trung gian',
    noFeeDes: 'Không còn để phí trung gian phức tạp làm trở ngại cho bạn trong việc lựa chọn nhà.',
    convenientTransactions: 'Giao dịch thuận tiện',
    convenientTransactionsDes: 'Quy trình mua bán minh bạch, cho thuê và bán nhà dễ dàng, thuận lợi.',
    whyChoose: 'Vì sao chọn 88go',
    outPlan: 'Gói dịch vụ của chúng tôi',
    filter: 'Tiêu chí lọc',
    rent: 'Giá thuê',
    sale: 'Giá bán',
    minimumPrice: 'Từ',
    maximumPrice: 'Đến',
    proportion: 'Diện tích',
    minimumArae: 'Từ',
    maximumArae: 'Từ',
    specialRequirment: 'Yêu cầu đặt biệt',
    search: 'Tìm kiếm',
    clearFilter: 'Xóa bộ lọc',
    gender: 'Giới tính',
    maleOnly: 'Ưu tiên nam',
    femaleOnly: 'Ưu tiên nữ',
    identity: 'Danh tính',
    choice: 'Đặc sắc',
    urgent: 'Hot',
    searchObject: 'Bạn không biết bắt đầu săn nhà từ đâu?',
    tellUs: 'Nói với chúng tôi',
    explainYourNeed: 'Hãy cho chúng tôi biết nhu cầu của bạn, 88go sẽ giúp tìm được một ngôi nhà hoàn hảo.',
    rentHouseQuestion1: 'Tài chính thuê nhà hiện tại của bạn?',
    rentHouseQuestion1Des: 'Vui lòng điền phạm vi tài chính, thông tin của bạn sẽ được bảo mật',
    minimumBudget: 'Tài chính tối thiểu',
    maximumBudget: 'Tài chính tối đa',
    rentHouseQuestion2: 'Loại hình nhà ở mà bạn mong muốn?',
    rentHouseQuestion2Des: 'Chọn tối đa 3 hạng mục, thông tin của bạn sẽ được bảo mật',
    prev: 'Trước',
    rentHouseQuestion3: 'Dạng nhà mà bạn mong muốn?',
    minRoom: 'Số lượng phòng tối thiểu',
    maxRoom: 'Số lượng phòng tối đa',
    rentHouseQuestion4: 'Bố cục mà bạn mong muốn?',
    rentHouseQuestion4Des: 'Vui lòng điền số lượng phòng bạn muốn, thông tin của bạn sẽ được bảo mật',
    squareQuestion: 'Diện tích mà bạn mong muốn?',
    squareQuestionDes: 'Vui lòng điền phạm vi diện tích cua bạn, thông tin của bạn sẽ được bảo mật',
    rentHouseQuestion5: 'Khu vực ưa thích hiện tại của bạn?',
    rentHouseQuestion5es: 'Vui lòng chọn khu vực ưa thích của bạn. Thông tin của bạn sẽ được giữ bảo mật',
    smartHouseSearch: 'Tìm kiếm nhà thông minh',
    loginRequire: 'Chức năng này cần được đăng nhập trước khi sử dụng',
    rentalHouseConditions: 'Điều kiện thuê lý tưởng của bạn',
    reset: 'Đặt lại',
    preferenceLayout: 'Bố cục ưu tiên',
    preferenceArea: 'Khu vực ưa thích',
    filledInOn: 'Điền thông tin',
    saleHouseQuestion1: 'Tài chính mua nhà hiện tại của bạn?',
    purchaseHouseConditions: 'Điều kiện mua nhà lý tưởng của bạn',
    newHouseConditions: 'Điều kiện về dự án mới lý tưởng của bạn',
    newHouseQuestion2: 'Phương án đề xuất ưa thích hiện tại của bạn?',
    factoryRentQuestion1: 'Tài chính thuê nhà xưởng hiện tại của bạn?',
    factorySaleQuestion1: 'Tài chính mua nhà xưởng hiện tại của bạn?',
    factoryQuestion2: 'Loại hình nhà xưởng ưa thích hiện tại của bạn',
    factoryConditions: 'Điều kiện về nhà xưởng lý tưởng của bạn',
    landRentQuestion1: 'Tài chính thuê đất hiện tại của bạn?',
    landSaleQuestion1: 'Tài chính mua đất hiện tại của bạn?',
    landQuestion2: 'Loại hình đất đai ưa thích hiện tại của bạn?',
    landConditions: 'Điều kiện lô đất lý tưởng của bạn',
    officeRentQuestion1: 'Tài chính thuê văn phòng hiện tại của bạn?',
    officeSaleQuestion1: 'Tài chính mua văn phòng hiện tại của bạn?',
    officeQuestion2: 'Loại hình văn phòng ưa thích hiện tại của bạn?',
    officeQuestion3: 'Dạng văn phòng ưa thích hiện tại của bạn?',
    officeConditions: 'Điều kiện văn phòng lý tưởng của bạn',
    shopRentQuestion1: 'Tài chính thuê cửa hàng hiện tại của bạn?',
    shopSaleQuestion1: 'Tài chính mua cửa hàng hiện tại của bạn?',
    shopQuestion2: 'Loại hình cửa hàng ưa thích hiện tại của bạn?',
    shopQuestion3: 'Dạng cửa hàng ưa thích hiện tại của bạn?',
    shopConditions: 'Điều kiện về cửa hàng lý tưởng của bạn',
    idealSlogan: 'Hãy tham khảo những ngôi nhà lý tưởng được 88go giới thiệu dưới đây!',
    rentTab: 'Tôi muốn thuê',
    saleTab: 'Tôi muốn mua',
    feature: 'Tính năng này chỉ dành cho người mua/người thuê',
    // eslint-disable-next-line max-len
    noResult: 'Hiện tại chưa có bất động sản nào phù hợp với nhu cầu của bạn. Bạn vui lòng điều chỉnh lại nhu cầu của mình hoặc tham khảo những căn nhà dưới đây mà chúng tôi giới thiệu đến cho bạn.',
  },
  footer: {
    aboutUs: 'Về chúng tôi',
    resource: 'Nguồn',
    listSearch: 'Danh sách',
    mapSearch: 'Bản đồ',
    whyChoose: 'Vì sao chọn 88go',
    ourPlan: 'Gói dịch vụ của chúng tôi',
    agentRegulations: 'Điều lệ đại diện',
    disclaimer: 'Tuyên bố miễn trừ trách nhiệm',
    termsOfMembershipAndPrivacyStatement: 'Điều khoản và quyền riêng tư',
    mortgageCalculator: 'Công cụ tính khoản vay',
  },
  rent: {
    title: 'Danh sách cho thuê',
    enTitle: 'Rental List',
    mapSearch: 'Bản đồ ',
    recommended: 'Đề xuất',
    forRent: 'Toàn bộ',
    forSale: 'Tất cả nhà để bán',
  },
  sale: {
    title: 'Danh sách mua bán',
    enTitle: 'Sale List',
  },
  preSale: {
    title: 'Danh sách dự án mới',
    enTitle: 'New Project List',
    recommended: 'Đề xuất',
    forPreSale: 'Mọi công trình xây dựng',
    determined: 'Đợi xác nhận',
  },
  factoryList: {
    title: 'Danh sách nhà xưởng',
    enTitle: 'Factory List',
    recommended: 'Đề xuất',
    forFactory: 'Tất cả các nhà xưởng',
    factoryForRent: 'Cho thuê nhà xưởng',
    factoryForSale: 'Bán nhà xưởng',
  },
  landList: {
    title: 'Danh sách đất đai',
    enTitle: 'Land List',
    recommended: 'Đề xuất',
    forLand: 'Tất cả đất đai',
    landForRent: 'Cho thuê đất',
    landForSale: 'Bán đất',
  },
  officeList: {
    title: 'Danh sách văn phòng',
    enTitle: 'Office List',
    recommended: 'Đề xuất',
    forOffice: 'Tất cả văn phòng',
    officeForRent: 'Cho thuê văn phòng',
    officeForSale: 'Bán văn phòng',
  },
  shopList: {
    title: 'Danh sách cửa hàng',
    enTitle: 'Shop List',
    recommended: 'Đề xuất',
    forShop: 'Tất cả các cửa hàng',
    shopForRent: 'Cho thuê cửa hàng',
    shopForSale: 'Bán cửa hàng',
  },
  video: {
    title: 'Videos',
    enTitle: 'Videos',
    home: 'Trang đầu',
    favorite: 'Sưu tầm',
    latest: 'Mới nhất',
    popular: 'Phổ biến',
    videoType: 'Hạng mục',
    search: 'Tìm kiếm tiêu đề video',
    views: 'Số lượt xem',
    send: 'Gửi',
    showAllComment: 'Đọc thêm',
    showLessComment: 'Ẩn bớt',
    replies: 'phản hồi',
  },
  search: {
    sort: 'Lọc',
    order: {
      1: 'Tin từ mới đến cũ',
      2: 'Tin từ cũ đến mới',
      3: 'Giá từ thấp đến cao',
      4: 'Giá từ cao đến thấp',
      5: 'Diện tích từ nhỏ đến lớn',
      6: 'Diện tích từ lớn đến nhỏ',
    },
    addr: 'Khu vực',
    city: 'Tỉnh / Thành',
    area: 'Quận / Huyện',
    ward: 'Phường / Xã',
    type: 'Loại hình',
    pattern: 'Kiểu nhà',
    rentRange: 'Giá thuê',
    saleRange: 'Phạm vi mua bán',
    unitRange: 'Khoảng giá đơn vị',
    totalRange: 'Tổng phạm vi giá',
    structure: 'Số phòng',
    proportion: 'Diện tích',
    houseDirection: 'Hướng nhà',
    floor: 'Tầng',
    special: 'Yêu cầu đặc biệt',
    identity: 'Yêu cầu khác',
    unlimited: 'Không giới hạn',
    // bedsit: 'Phòng trọ',
    // doubleRoom: 'Căn hộ cho thuê',
    // studio: 'Căn hộ Studio',
    // wholeFloor: 'Nhà nguyên tầng',
    // apartment: 'Chung cư',
    // condo: 'Căn hộ',
    // house: 'Nhà liền kề',
    // vila: 'Biệt thự',
    room: 'Phòng',
    roomUp: 'Phòng trở lên',
    squareMeters: 'm2',
    expand: 'Mở rộng tùy chọn',
    collapse: 'Thu gọn tùy chọn',
    // eastWest: 'Hướng đông tây',
    // northSouth: 'Hướng nam bắc',
    // SouthWest: 'Hướng tây nam',
    // northEast: 'Hướng đông bắc',
    // northWest: 'Hướng tây bắc',
    // southEast: 'Hướng đông nam',
    nearMRT: 'Gần phương tiện công cộng',
    pet: 'Nuôi thú cưng',
    furnace: 'Bếp lửa',
    parking: 'Chỗ để xe',
    elevator: 'Thang máy',
    balcony: 'Ban công',
    shortTerm: 'Dịch vụ cho thuê ngắn hạn',
    hostPublished: 'Tin chủ sỡ hữu',
    maleOnly: 'Ưu tiên nam',
    femaleOnly: 'Ưu tiên nữ',
    officeWorker: 'Nhân viên văn phòng',
    family: 'Gia đình',
    student: 'Học sinh',
    native: 'Người trong nước',
    foreigners: 'Người nước ngoài',
    includingParking: 'Bao gồm chỗ đậu xe',
    starSchool: 'Trường học hàng đầu',
    layout: 'Có sơ đồ mẫu',
    above: 'Trên',
    caseType: 'Trường hợp',
  },
  map: {
    RentalMapSearch: 'Tìm kiếm bản đồ cho thuê',
    RentalMapSearchEn: 'Rental Map Search',
    SaleMapSearch: 'Tìm kiếm bản đồ mua bán',
    SaleMapSearchEn: 'Sale Map Search',
    preSaleMapSearch: 'Tìm kiếm bản đồ trước khi bán hàng',
    prsSaleMapSearchEn: 'Pre-Sale Map Search',
    factoryMapSearch: 'Tìm kiếm bản đồ nhà xưởng',
    factoryMapSearchEn: 'Factory Map Search',
    landMapSearch: 'Tìm kiếm bản đồ đất đai',
    landMapSearchEn: 'Land Map Search',
    officeMapSearch: 'Tìm kiếm bản đồ văn phòng',
    officeMapSearchEn: 'Office Map Search',
    shopMapSearch: 'Tìm kiếm bản đồ cửa hàng',
    shopMapSearchEn: 'Shop Map Search',
    listSearch: 'Truy vấn danh sách',
    county: 'Quận',
    district: 'Huyện',
    type: 'Kiểu',
    minimumPrice: 'Từ',
    maximumPrice: 'Đến',
    minimumNewProjectPrice: 'Từ',
    maximumNewProjectPrice: 'Đến',
    minimumsSquare: 'Từ',
    maximumsSquare: 'Đến',
    selectTime: 'Vui lòng chọn thời gian',
  },
  detail: {
    none: 'không',
    can: 'có thể',
    not: 'không thể',
    month: 'tháng',
    months: 'tháng',
    tenThousand: 'Mười nghìn',
    moveAnytime: 'Có thể chuyển đến bất cứ lúc nào',
    structure: 'Loại',
    room: 'Phòng ngủ',
    hall: 'Phòng khách',
    bathroom: 'Nhà vệ sinh',
    balcony: 'Ban công',
    proportion: 'Diện tích',
    squareMeter: 'm2',
    floor: 'Tầng lầu',
    houseDirection: 'Hướng nhà',
    aroundTheLocation: 'Vị trí xung quanh',
    location: 'Vị trí',
    surroundings: 'Môi trường xung quanh',
    traffic: 'Giao thông',
    education: 'Giáo dục',
    life: 'Nhà hàng',
    medical: 'Bệnh viện',
    live: 'Đất/Tiện ích công cộng',
    equipmentAndRules: 'Thiết bị và nội quy',
    equipment: 'Nội thất cơ bản',
    homeEquipment: 'Thiết bị gia dụng',
    basicEquipment: 'Thiết bị cơ bản',
    handlingEquipment: 'Thiết bị xếp dỡ',
    washingMachine: 'Máy giặt',
    refrigerator: 'Tủ lạnh',
    network: 'Internet',
    television: 'TV',
    payTelevision: 'Truyền hình thu phí',
    waterHeater: 'Máy nước nóng',
    airConditioner: 'Máy lạnh',
    gas: 'Gas',
    microWave: 'Lò vi sóng',
    bed: 'Giường',
    closet: 'Tủ quần áo',
    sofa: 'Sofa',
    table: 'Bàn ghế',
    parking: 'Chỗ đậu xe',
    elevator: 'Thang máy',
    fireSecurity: 'PCCC',
    rentalRules: 'Quy định cho thuê',
    pet: 'Nuôi thú cưng',
    furnace: 'Nấu bếp',
    tax: 'Thuế',
    householdRegistration: 'Nhập hộ',
    godTable: 'Bàn thờ',
    identity: 'Xác nhận danh tính',
    housingDetails: 'Chi tiết nhà ở',
    factoryDetails: 'Chi tiết nhà xưởng',
    landDetails: 'Chi tiết đất đai',
    officeDetails: 'Chi tiết văn phòng',
    shopDetails: 'Chi tiết cửa hàng',
    rentalInstructions: 'Chi tiết thuê nhà',
    factoryRentalInstructions: 'Chi tiết thuê nhà',
    rentIncludes: 'Giá thuê bao gồm',
    deposit: 'Tiền cọc',
    managementFees: 'Phí quản lý',
    minimumRentalPeriod: 'Thời gian thuê tối thiểu',
    houseDescription: 'Mô tả nhà ở',
    factoryInfo: 'Thông tin nhà xưởng',
    landInfo: 'Thông tin đất đai',
    salesDescription: 'Mô tả bán hàng',
    descriptionOfHousePropertyRegistration: 'Hướng dẫn đăng ký quyền sở hữu',
    houseQA: 'Hỏi đáp nhà ở',
    factoryQA: 'Hỏi đáp về nhà xưởng',
    landQA: 'Hỏi đáp đất đai',
    officeQA: 'Hỏi đáp văn phòng',
    shopQA: 'Hỏi đáp về cửa hàng',
    houseQADescription: 'Nhập nội dung câu hỏi và nhà phát hành sẽ trả lời bạn trong thời gian sớm nhất',
    contentPLaceholder: 'Vui lòng nhập nội dung',
    property: 'Tài sản',
    statutoryPurpose: 'Chứng nhận quyền sử dụng đất',
    buildingArea: 'Chứng nhận quyền sử dụng đất',
    similarHouses: 'Diện tích xây dựng',
    nearbyFactories: 'Nhà xưởng gần đó',
    nearbyLand: 'Đất gần đó',
    landlordPage: 'Kiểm tra',
    sendQuestion: 'Gửi câu hỏi',
    postedBy: 'Gửi bởi',
    publishedIn: 'Đăng trên',
    viewAll: 'Xem tất cả ảnh',
    houseAge: 'Năm xây dựng nhà',
    parkingSpace: 'Chỗ đậu xe',
    report: 'Báo cáo thuộc tính này',
    share: 'Chia sẻ',
    shareToFriends: 'Chia sẻ ngôi nhà tốt của bạn với nhiều bạn bè hơn!',
    copyLink: 'Sao chép liên kết',
    shareOnFb: 'Chia sẻ lên Facebook',
    shareOnZalo: 'Chia sẻ lên Zalo',
    shareother: 'Những cách khác',
    notice: '88GO nhắc nhở bạn: Người đăng xem nhà không còn thuận tiện nữa, việc yêu cầu đặt cọc với lý do tài sản đang có nhu cầu cao là lừa đảo!',
    nearbyQuotations: 'Báo giá thị trường',
    investment: 'đầu tư',
    finished: 'Hoàn thành',
    handover: 'Nhà bàn giao',
    houseCondition: 'Tình trạng nhà',
    openSale: 'Bán công khai',
    loanRatio: 'Tỷ lệ vay',
    receptionCenter: 'Sảnh lễ tân',
    layout: 'Bố cục mở',
    salesInfo: 'Thông tin bán hàng',
    community: 'Cộng đồng',
    building: 'tòa nhà',
    buildingPlanning: 'Quy hoạch nhà',
    aboveGround: 'trên mặt đất',
    underGround: 'dưới lòng đất',
    buildingMaterials: 'Vật liệu xây dựng',
    builderInfo: 'Thông tin người xây dựng',
    constructionCompany: 'Công ty xây dựng',
    architecturalDesign: 'Thiết kế kiến trúc',
    sellingByYourself: 'Tự bán hàng',
    features: 'Tính năng',
    comparePrices: 'So sánh giá',
    service: 'Phục vụ',
    legalDocument: 'Tài liệu pháp lý',
    notUploadedYet: 'Chưa tải lên',
    latestNews: 'Tin mới nhất',
    viewsAll: 'Xem tất cả',
    newProjectStory: 'Chuyện dự án',
    appQrcode: 'Quét mã QR bằng ứng dụng',
    clickLink: 'Nhấp vào liên kết',
    saleBySplits: 'Bán theo chia tách',
    widthOfTheFrontRoad: 'Khoảng cách đến mặt đường',
    buildingHeightLimit: 'Xây dựng giới hạn chiều cao',
    specification: 'Đặc điểm kỹ thuật',
    passengerElevator: 'Thang máy chở khách',
    freightElevator: 'Thang máy chở hàng',
    includedInRent: 'Bao gồm trong tiền thuê',
    nearbyOffice: 'Văn phòng lân cận',
    nearbyShop: 'Cửa hàng lân cận',
    averageRental: 'giá giao dịch cho thuê trung bình lân cận',
    averageSale: 'Giá bán trung bình lân cận',
    averageAmount: 'Giá trung bình',
  },
  publisher: {
    entList: 'Danh sách cho thuê',
    saleList: 'Danh sách mua bán',
    more: 'Xem thêm',
    noHouseForRent: '- Hiện tại không có bất động sản nào cho thuê-',
    noHouseForSale: '- Hiện tại không có bất động sản nào mua bán - ',
    openRating: 'Xem đánh giá',
    currently: 'Hiện nay có tổng cộng',
    house: 'phòng',
    case: 'trường hợp',
    factory: 'nhà xưởng',
    land: 'đất',
    newHouse: 'mục mới vừa thêm vào hôm nay',
    items: '',
    tabs: {
      home: 'Trang đầu',
      rent: 'Danh sách bất động sản cho thuê',
      sale: 'Danh sách nhà để bán',
      detail: 'Giới thiệu chi tiết',
      message: 'Thông điệp tư vấn',
    },
    working: 'luyện tập',
    views: 'Lượt xem',
    companyInfo: 'Thông tin công ty',
    company: 'công ty',
    workingYears: 'năm',
    workingExperience: 'kinh nghiệm',
    qaDescription: 'Nhập nội dung câu hỏi và nhà điều hành sẽ trả lời bạn trong thời gian sớm nhất',
    onlyTenItems: 'Chỉ có 10 mục mới nhất được hiển thị ở đây',
    evaluationDetails: 'Đánh giá chi tiết',
    summary: 'Bản tóm tắt',
    evaluate: 'đánh giá',
    comment: 'Bình luận',
    rating: 'Số điểm',
    explanation: 'Giải trình',
  },
  info: {
    learnMore: 'Tìm hiểu thêm',
    outPlan: 'Gói dịch vụ của chúng tôi',
    outPlanEn: 'Our Membership Plan',
    agencyRegulations: 'Điều lệ đại diện',
    agencyRegulationsEn: 'Agent',
    choose88go: 'Vì sao chọn 88go',
    choose88goEn: 'Về 88go',
    disclaimer: 'Tuyên bố miễn trừ trách nhiệm',
    disclaimerEn: 'Disclaimer',
    policy: 'Điều khoản và quyền riêng tư',
    policyEn: 'Membership Rules & Privacy Policy',
  },
  about: {
    slogan1: 'Chi phí hợp lý!',
    slogan2: 'Nắm bắt tương lai mới',
    welcome: 'Chào mừng đến 88go',
    description1: 'Chúng tôi là nền tảng cung cấp đầy đủ các giải pháp nhà ở, bất kể nhu cầu nhà ở của bạn là gì, 88go sẽ là đối tác tin cậy của bạn.'
            + ' Chúng tôi mong muốn được cung cấp cho bạn dịch vụ tốt nhất trong hành trình lưu trú này.Chung tay cùng bạn thực hiện nơi ở lí tưởng trong tương lai',
    slogan3: 'Nguồn cung nhà ở đa dạng, nhiều sự lựa chọn',
    description2: '88go tập hợp nhiều loại hình bất động sản chất lượng để đáp ứng mọi nhu cầu và ngân sách.'
            + ' tĩnh.'
            + ' Chúng tôi cũng cung cấp khả năng tương thích trên nhiều thiết bị để bạn có thể tìm thấy ngôi nhà lý tưởng của mình mọi lúc, mọi nơi',
    download: 'Tải xuống',
    slogan4: 'Không mất phí môi giới, tiết kiệm chi phí',
    description3: 'Chúng tôi tin chắc rằng việc thuê và sở hữu một ngôi nhà phải dễ dàng và thuận lợi chứ không phải nhiều phiền toái phức tạp.'
            + ' 88go hứa hẹn đối với chi phí môi giới sẽ không còn là trở ngại cho bạn trong việc lựa chọn nhà.'
            + ' Chỉ với một khoản phí thành viên nhỏ, bạn có thể tận hưởng các giao dịch tại nhà an toàn và tối đa hóa lợi ích từ các giao dịch tại nhà của mình',
    ourPlan: 'Tìm hiểu về các gói dịch vụ của của chúng tôi',
    slogan5: 'Yên tâm thuê nhà, đối tác sẽ hỗ trợ bạn',
    description4: 'Tại 88go, chúng tôi cam kết tạo ra trải nghiệm giao dịch an toàn và suôn sẻ cho bạn, để bạn có thể tự tin lựa chọn nơi cư trú lý tưởng cho mình.'
            + ' Các đại lý của chúng tôi sử dụng kinh nghiệm và kiến ​​thức chuyên môn của mình để hỗ trợ bạn với sự an tâm tuyệt đối.'
            + ' Cho dù đó là hỗ trợ pháp lý, bảo vệ giao dịch hay xử lý chi tiết, họ sẽ nỗ lực hết mình để đảm bảo rằng lợi ích của bạn được bảo vệ ở mức độ lớn nhất',
    slogan6: 'Hãy để chúng tôi thực hiện giao dịch về ngôi nhà của bạn',
    slogan7: 'Thêm sự tự tin và tiện lợi',
    getStared: 'Bắt đầu ngay bây giờ tìm người phù hợp và ngôi nhà của bạn',
    description5: 'Tại 88go, chúng tôi cung cấp cho bạn nền tảng để bắt đầu tìm kiếm ngôi nhà lý tưởng cũng như các ưu đãi về nhà ở tốt.'
            + ' Cho dù bạn là người thuê nhà hay chủ nhà, chúng tôi sẽ giúp bạn tìm được ngôi nhà phù hợp và hợp đồng phù hợp.'
            + ' Hãy cùng nhau bắt đầu cuộc hành trình thú vị này',
    anyQuestion: 'Nếu bạn có bất kỳ câu hỏi nào hãy liên hệ với chúng tôi',
    contactUs: 'Liên hệ chúng tôi',
  },
  plan: {
    slogan1: 'Liên kết ngôi nhà lí tưởng,nhiều sự lựa chọn',
    description1: 'Thông qua các gói đăng tin chuyên nghiệp, danh sách tin của bạn sẽ được hiển thị nhiều hơn và hiệu ứng hiển thị vượt trội.'
            + ' Các thành viên tham gia với chúng tôi có thể nhận được thông tin về nhà ở ,chủ nhà đầy đủ và an toàn hơn, đồng thời thông qua gói đại lý,'
            + ' bạn có thể nhận được nhiều lợi ích hơn trong các giao dịch',
    slogan2: 'Làm cho ngôi nhà của bạn tỏa sáng độc đáo',
    description2: 'Gói đăng tin đơn giản và nhanh nhất',
    rent: 'Cho thuê',
    sale: 'Mua bán',
    advertise: 'Quảng cáo',
    viewImageAd: 'Hướng dẫn đặt quảng cáo hình ảnh',
    whatIsAd: 'Quảng cáo hình ảnh là gì?',
    // eslint-disable-next-line max-len
    whatIsAdDes: 'Là hình thức sử dụng hình ảnh để thu hút người dùng, khi họ nhấn vào sẽ dẫn đến website của bạn hay một trang đăng nhập được thiết kế đặc biệt, hoặc danh sách nhà mới được đăng trên trang website 88gohouse.com.',
    benefitsOfBanner: 'Lợi ích của quảng cáo biểu ngữ',
    benefitsOfBannerDes1: '1.Quảng bá thương hiệu',
    benefitsOfBannerDes2: '2.Tăng lưu lượng truy cập vào trang web của bạn',
    benefitsOfBannerDes3: '3.Tăng lượng khách hàng tiềm năng và tăng doanh thu bán hàng',
    imageLocation: 'Vị trí hình ảnh và mô tả kích thước',
    size: 'kích thước (hình ảnh)',
    bannerAdWeb: 'Phiên bản web quảng cáo quay vòng trên trang chủ',
    bannerAdMobile: 'Phiên bản di động của quảng cáo quay vòng trên trang chủ',
    sidebarAd: 'Trang chủ phiên bản web quảng cáo thanh bên sidebar',
    notice: 'Đề phòng',
    // eslint-disable-next-line max-len
    noticeDes1: '1.Tùy theo cấu hình màn hình, khuyến nghị nên đặt hình ảnh và văn bản chính ở vùng an toàn màu xanh lục của sơ đồ bên dưới cho hình ảnh xoay vòng trên trang chủ của phiên bản web.',
    noticeDes2: '2.Tùy thuộc vào loại màn hình, một số phần vẫn có thể bị chặn.',
    floatAd: 'Phiên bản web quảng cáo nổi',
    otherNotice: 'Những điều khác cần lưu ý',
    otherNoticeDes1: '1.Gói quảng cáo một hình ảnh chỉ tải lên một quảng cáo hình ảnh ở vị trí được chỉ định.',
    otherNoticeDes2: '2.Thời gian phát hành quảng cáo bằng hình ảnh được tính là 30 ngày',
    otherNoticeDes3: '3.Sau khi ứng dụng quảng cáo hình ảnh thành công, thời gian xét duyệt sẽ khoảng 1-3 ngày làm việc.',
    singleRentListing: 'Gói đăng tin cho thuê 1 lần',
    singleSaleListing: 'Gói đăng tin mua bán 1 lần',
    rentListingDescription: 'Thích hợp cho chủ nhà/đại lý chỉ có số lượng ít nhu cầu đăng tin',
    basicPlan: 'Gói cơ bản',
    basicPlanDescription: 'Thích hợp cho chủ nhà/người bán cần đăng tin đơn giản',
    basicPlanDetail: {
      limit: 'Thời hạn',
      price: '₫/2 tháng',
      publish: 'Đăng tin',
      items: 'Tin',
      dollar: 'nhân dân tệ',
      point: 'Điểm',
      day: 'Ngày',
      month: 'Tháng',
      hours: 'Trong vòng 72 giờ có thể thay đổi địa chỉ',
      times: 'Lần',
      publishTime: 'Thời gian đăng tin',
      noExposure: 'Không phổ biến',
      exposure: 'Phổ biến',
    },
    freePublishing: 'Đăng bài cá nhân miễn phí',
    singleListingNotice: '※Thời hạn hiệu lực của gói: Có hiệu lực trong vòng',
    singledaysofpurchase: 'ngày kể từ ngày mua',
    basicPlanNotice: '※Thời hạn hiệu lực của gói: Có hiệu lực trong vòng',
    basicdaysofpurchase: 'ngày kể từ ngày mua. Các vật phẩm trong gói phải được sử dụng trong thời hạn hiệu lực và sẽ không hợp lệ sau ngày hết hạn',
    buyPlan: 'Mua gói',
    bestDeal: 'Tiết kiệm nhất',
    proPlan: 'Gói chuyên nghiệp',
    proPlanDescription: 'Danh sách tin được nổi bật hơn',
    vipPlan: 'Gói VIP',
    vipPlanDescription: 'Danh sách tin mong muốn tiếp cận lớn nhất',
    slogan3: 'Quản lý bất động sản nhanh chóng, đơn giản và hiệu quả',
    description3: 'Thích hợp cho nhu cầu đăng tin nhiều bất động sản',
    membershipPlan: 'Gói thành viên',
    membershipPlanDescription: 'Thích hợp cho thành viên cần mua hoặc thuê nhà',
    membershipPlanDetail: {
      landloardInfo: 'Đầy đủ thông tin liên hệ của chủ nhà',
      rateToLandloard: 'Tiến hành đánh giá',
      limit: 'Thời hạn thành viên',
    },
    slogan4: 'Làm cho ngôi nhà của bạn thêm phần nổi bật',
    description4: 'Tăng cường dịch vụ,độ phổ biến cao hơn',
    service: {
      updateSort: 'Cập nhật bài đăng hàng đầu',
      manualUpdates: 'Cập nhật thủ công',
      manualUpdatesDescription: '(Bấm thủ công để cập nhật bài đăng)',
      automaticUpdates: 'Cập nhật tự động',
      automaticUpdatesDescription: '(Hệ thống tự động cập nhật bài đăng)',
      regularUpdates: 'Thiết lập cập nhật',
      regularUpdatesDescription: '(Tùy chỉnh số lần và thời gian cập nhật)',
      adService: 'Quảng cáo',
      prioritization: 'Ưu tiên',
      prioritizationDescription: '(Luôn được xếp trước tin thông thường',
      preferred: 'Ưa thích',
      preferredDescription: '(Trang chủ)',
      recommended: 'Đề xuất',
      recommendedDescription: '(Danh sách)',
      labelingService: 'Đánh dấu',
      pin: 'Ghim đầu trang',
      pinDescription: '(Đánh dấu nổi bật đầu trang)',
      choice: 'Chọn lọc',
      choiceDescription: '(Đánh dấu nổi bật chọn lọc)',
      expedtedTag: 'Gắn nhãn',
      expedtedTagDescription: '(Đánh dấu nổi bật "HOT")',
      publishByDedicated: 'Đăng bởi đội ngũ chuyên môn',
      publishByDedicatedDescription: '(Thư kí hỗ trợ đăng tin)',
    },
    slogan5: 'Giao dịch nhà ở của bạn thêm đơn giản',
    description5: 'Gói cơ bản phù hợp cho mọi cá nhân',
    removeAdPlan: 'Gỡ quảng cáo',
    removeAdPlanDescription: 'Thích hợp cho những thành viên không thích bị làm phiền bởi quảng cáo',
    removeAllBanner: 'Loại bỏ tất cả quảng cáo trên trang web',
    removeAgentPlan: 'Gỡ môi giới',
    removeAgentPlanDescription: 'Phù hợp cho thành viên không muốn bị làm phiền bởi người môi giới',
    removeAgentBanner: 'Loại bỏ tất cả các danh sách môi giới trung gian',
    qa: 'Vấn đề thường gặp',
    qaDescription: 'Bạn có bất kỳ câu hỏi nào về gói dịch vụ của chúng tôi, vui lòng liên hệ với chúng tôi',
    qaContactUs: 'Liên hệ với chúng tôi',
    subErr: 'Trạng thái tài khoản phụ không thể mua gói. Nếu bạn có nhu cầu mua, vui lòng chuyển sang tài khoản đại lý.',
  },
  agent: {
    slogan1: 'Phân chia lợi nhuận ba cấp,cùng nhau tạo thu nhập',
    subSlogan: 'Mời người khác tham gia và giao dịch thành công sẽ mang lại lợi ích cho bạn',
    description1: 'Để cho phép mọi thành viên được hưởng nhiều lợi nhuận hơn, 88go đã đặc biệt triển khai kế hoạch chia sẻ lợi nhuận ba cấp dành cho thành viên của chúng tôi.'
            + ' Khi bạn mời thành công người khác tham gia làm thành viên, bạn cũng sẽ nhận được phần lợi nhuận được chia sẻ từ các giao dịch.'
            + ' Điều đáng mong đợi hơn là bạn cũng có thể chia sẻ lợi nhuận đại lý của bên kia,'
            + ' không chỉ các giao dịch của bạn mà còn cả các thành viên mà bạn giới thiệu và các thành viên mà họ giới thiệu',
    process: 'Quy trình đại lý',
    join: 'Trước khi tham gia',
    joinDescription2: 'Trước khi tham gia 88go, bạn có thể trở thành thành viên đại lý của đối phương bằng cách điền mã giới thiệu.'
            + ' Sau khi tham gia thành viên của chúng tôi, bạn cũng sẽ có cơ hội trở thành đại lý',
    becomeMember: 'Trở thành thành viên',
    becomeMemberDescription: 'Sau khi trở thành thành viên, bạn sẽ nhận được một bộ mã độc quyền, bạn có thể chia sẻ mã này cho người thân, bạn bè xung quanh và mời họ tham gia 88go.',
    becomeAgent: 'Trở thành đại lý',
    becomeAgentDescription: 'Sau khi mời người khác tham gia thành công, bạn sẽ có tư cách đại lý, thông qua kế hoạch đại lý,'
            + ' bạn sẽ có thu nhập hoa hồng và lợi nhuận giao dịch của những người bạn mời',
    bonus: 'Cách tính hoa hồng',
    benefits: 'Lợi ích phân chia lợi nhuận 3 cấp',
    invite: 'Mời nhiều người',
    inviteDescription: 'Mô hình liên kết này giúp tầm ảnh hưởng và thu nhập của bạn ngày càng mở rộng. Càng mời nhiều người, bạn càng nhận được nhiều hoa hồng.',
    accounting: 'Minh bạch tài khoản',
    accountingDescription: 'Thống kê thu nhập từ ngày 1 đến ngày 30 hàng tháng sẽ được gửi cách tháng'
            + ' và các thành viên cũng có thể xem kết quả hoạt động ngay lập tức để đảm bảo rằng kết quả thu nhập của bạn được kịp thời và minh bạch',
    profits: 'Lợi nhuận phân cấp',
    profitsDescription: 'Mỗi tháng ngay cả khi các thành viên bạn giới thiệu, bạn có thể chia sẻ lợi ích giao dịch của họ',
    joinHands: 'Chung tay chia sẻ vinh quang cuộc sống',
    joinHandsDescription: 'Mô hình liên kết này sẽ cho phép tầm ảnh hưởng và thu nhập của bạn tiếp tục mở rộng, trở thành một phần quan trọng của cộng đồng đôi bên cùng có lợi.'
            + ' 88go tin rằng mô hình cùng có lợi này có thể mang lại nhiều giá trị hơn cho mỗi thành viên và cho phép chúng ta cùng nhau tạo ra một tương lai mua nhà cho thuê tốt hơn',
    join88go: 'Gia nhập đội ngũ của 88go',
  },
  disclaimer: {
    slogan: 'Cảm ơn bạn đã sử dụng mạng lưới giao dịch 88go',
    description1: 'Chào mừng bạn đến với mạng lưới giao dịch 88go (sau đây gọi tắt là “chúng tôi”, “nền tảng”)',
    description2: 'Vui lòng đọc kỹ tuyên bố miễn trừ trách nhiệm sau đây trước khi sử dụng dịch vụ của chúng tôi. Bằng cách sử dụng dịch vụ của chúng tôi, bạn đồng ý với các điều khoản này',
    list: {
      title1: '1.Độ chính xác của thông tin',
      description1: 'Chúng tôi nỗ lực hết sức để đảm bảo rằng thông tin và nội dung danh sách trên Nền tảng là chính xác và đầy đủ.'
                + ' Tuy nhiên, chúng tôi không thể đảm bảo tính chính xác hoặc đầy đủ của tất cả thông tin.'
                + ' Bạn nên đưa ra phán đoán của riêng mình và xác minh tính chính xác của thông tin danh sách.',
      title2: '2.Giao dịch cho thuê và mua nhà',
      description2: '2.1Chúng tôi chỉ cung cấp nền tảng thông tin cho thuê và mua nhà và không tham gia vào các giao dịch thực tế.'
                + ' Giao dịch thuê hoặc mua nhà của bạn trên nền tảng là do bạn quyết định và chúng tôi không chịu trách nhiệm về hiệu suất hoặc kết quả của giao dịch',
      description3: '2.2Chúng tôi khuyên bạn nên tiến hành điều tra và xem xét đầy đủ trước khi thực hiện bất kỳ giao dịch nào để đảm bảo tính hợp pháp và khả thi của giao dịch',
      title3: '3.Nội dung và dịch vụ của bên thứ ba',
      description4: 'Nền tảng có thể chứa nội dung và liên kết từ các bên thứ ba không liên kết với chúng tôi và chúng tôi không chịu trách nhiệm về tính chính xác,'
                + ' tính hợp pháp hoặc sự an toàn của chúng.'
                + ' Bạn tự chịu rủi ro khi sử dụng những nội dung và dịch vụ này',
      title4: '4.Rủi ro sử dụng',
      description5: 'Việc sử dụng dịch vụ của chúng tôi là rủi ro của riêng bạn.'
                + ' Chúng tôi không đảm bảo rằng Nền tảng sẽ luôn không có lỗi,'
                + ' an toàn hoặc không bị gián đoạn và chúng tôi cũng không chịu trách nhiệm về bất kỳ nội dung nào có thể gây hư hỏng cho hệ thống máy tính của bạn',
      title5: '5.Giới hạn trách nhiệm pháp lý',
      description6: 'Trong phạm vi tối đa được pháp luật hiện hành cho phép, chúng tôi sẽ không chịu trách nhiệm về bất kỳ tổn thất hoặc thiệt hại nào,'
                + ' dù trực tiếp, gián tiếp, ngẫu nhiên, đặc biệt hay do hậu quả, phát sinh từ việc sử dụng dịch vụ của chúng tôi',
      title6: '6.Thay đổi đối với Tuyên bố miễn trừ trách nhiệm',
      description7: 'Chúng tôi có quyền cập nhật tuyên bố từ chối trách nhiệm của mình bất cứ lúc nào, vì vậy vui lòng kiểm tra lại thường xuyên để đảm bảo bạn biết nội dung mới nhất',
      description8: 'Nếu bạn có bất kỳ câu hỏi nào về tuyên bố từ chối trách nhiệm của chúng tôi, vui lòng liên hệ với chúng tôi',
    },
    notice: 'Tuyên bố từ chối trách nhiệm trên có hiệu lực kể từ [Ngày có hiệu lực]. <br>'
            + 'Nếu bạn có bất kỳ câu hỏi hoặc thắc mắc nào về Điều khoản của chúng tôi, vui lòng liên hệ với chúng tôi',
    contactUs: 'Liên hệchúng tôi',
  },
  policy: {
    slogan: 'Cảm ơn bạn đã sử dụng mạng lưới giao dịch 88go',
    subTitle1: 'Điều khoản thành viên',
    subTitle2: 'Cam kết bảo mật',
    description1: 'Chào mừng bạn đến với mạng lưới giao dịch 88go (sau đây gọi tắt là “chúng tôi”, “nền tảng”)'
            + 'Vui lòng đọc kỹ tuyên bố miễn trừ trách nhiệm sau đây trước khi sử dụng dịch vụ của chúng tôi. Bằng cách sử dụng dịch vụ của chúng tôi, bạn đồng ý với các điều khoản này',
    description2: 'Chúng tôi rất coi trọng quyền riêng tư của bạn và cam kết bảo vệ thông tin cá nhân của bạn.'
            + ' Tuyên bố về quyền riêng tư của chúng tôi nêu chi tiết cách chúng tôi thu thập, sử dụng và bảo vệ thông tin của bạn.',
    list: {
      title1: '1.Sử dụng tài khoản',
      description1: '1.1 Thông tin cá nhân bạn cung cấp khi đăng ký phải đúng sự thật và chính xác.'
                + ' Tài khoản của bạn chỉ dành cho mục đích sử dụng cá nhân và không được chuyển nhượng, chia sẻ hoặc cho bên thứ ba thuê.',
      description2: '1.2 Bạn có trách nhiệm bảo vệ tài khoản và mật khẩu của mình và chịu trách nhiệm về mọi hoạt động diễn ra trong tài khoản của mình.'
                + ' Nếu bạn có bất kỳ nghi ngờ nào về việc sử dụng trái phép tài khoản của mình, vui lòng thông báo ngay cho chúng tôi',
      title2: '2. Quyền chủ sở hữu và hành vi môi giới',
      description3: '2.1 Nền tảng của chúng tôi được thiết kế để cung cấp dịch vụ cho thuê và mua nhà và các hoạt động trung gian đều bị cấm trên nền tảng của chúng tôi',
      description4: '2.2 Nếu chúng tôi phát hiện bạn là người trung gian, tài khoản của bạn sẽ bị xóa ngay lập tức và gói bạn mua ban đầu sẽ không được hoàn trả',
      title3: '3. Phí thành viên và thanh toán',
      description5: '3.1 Bạn có thể chọn mua các gói khác nhau mà chúng tôi cung cấp để nhận được các dịch vụ và tính năng cụ thể hơn. Phương thức thanh toán và phí sẽ tùy thuộc vào gói bạn chọn',
      description6: '3.2 Tất cả các khoản thanh toán sẽ được thực hiện một cách an toàn và chúng tôi sẽ bảo vệ thông tin thanh toán của bạn.'
                + ' Sau khi thanh toán hoàn tất, bạn sẽ được hưởng ngay các dịch vụ tương ứng theo gói đã chọn',
      title4: '4. Quy tắc thành viên',
      description7: '4.1 Bạn phải tuân thủ tất cả các luật và quy định hiện hành, đồng thời luôn lịch sự và tôn trọng khi sử dụng nền tảng của chúng tôi',
      description8: '4.2 Bạn không được phép đăng tải thông tin sai lệch, xâm phạm quyền của người khác hoặc tham gia vào các hoạt động bất hợp pháp trên nền tảng',
      title5: '5. Giới hạn trách nhiệm pháp lý',
      title6: '6. Thay đổi đối với Tuyên bố miễn trừ trách nhiệm',
    },
    privacy: {
      title1: '1. Thông tin được thu thập',
      description1: '1.1 Chúng tôi có thể thu thập thông tin cá nhân mà bạn cung cấp khi đăng ký, sử dụng Dịch vụ, tham gia các sự kiện hoặc liên lạc với chúng tôi',
      description2: '1.2 Chúng tôi có thể tự động thu thập dữ liệu sử dụng nhất định, chẳng hạn như hồ sơ truy cập, địa chỉ IP, thông tin thiết bị, để cải thiện dịch vụ của chúng tôi',
      title2: '2. Sử dụng thông tin',
      description3: '2.1 Chúng tôi sử dụng thông tin của bạn để cung cấp, duy trì và cải thiện dịch vụ của chúng tôi cũng như liên hệ với bạn.',
      description4: '2.2 Chúng tôi sẽ không chia sẻ thông tin cá nhân của bạn với các bên thứ ba trái phép',
      title3: '3. Bảo mật thông tin',
      description5: 'Chúng tôi thực hiện các biện pháp bảo mật hợp lý để bảo vệ thông tin của bạn khỏi bị truy cập, sử dụng hoặc tiết lộ trái phép',
      title4: '4. Xóa dữ liệu',
      // eslint-disable-next-line max-len
      description6: "{'Sửa đổi và xóa thông tin cá nhân, Bạn có quyền kiểm tra, sửa đổi hoặc xóa những thông tin cá nhân mà chúng tôi nắm giữ. Hãy gửi yêu cầu của bạn qua địa chỉ email của chúng tôi là 88gohouse@gmail.com với nội dung xóa thông tin [số điện thoại di động của bạn]. Chúng tôi sẽ giúp bạn xóa mọi dữ liệu cá nhân mà chúng tôi nắm giữ.'}",
    },
    notice: 'Các điều khoản thành viên và tuyên bố về quyền riêng tư ở trên có hiệu lực kể từ [ngày có hiệu lực] và chúng tôi có quyền cập nhật chúng bất cứ lúc nào.'
            + ' Vui lòng xem lại các Điều khoản này thường xuyên để đảm bảo bạn biết được thông tin mới nhất. <br>'
            + 'Nếu bạn có bất kỳ câu hỏi hoặc thắc mắc nào về điều khoản thành viên hoặc tuyên bố về quyền riêng tư của chúng tôi, vui lòng liên hệ với chúng tôi',
  },
  friendRules: {
    title: 'Quy tắc đề xuất bạn bè',
    enTitle: 'Friend Recommendation Rules',
    slogan1: 'Được bạn bè giới thiệu, chia sẻ và công bố phần thưởng!',
    subSlogan: 'Mời người khác tham gia và cùng nhau thưởng thức danh sách phần thưởng',
    // eslint-disable-next-line max-len
    description1: 'Để cho phép mọi thành viên được hưởng nhiều phần thưởng hơn, 88go đã triển khai chương trình giới thiệu bạn bè thành viên độc đáo của chúng tôi.Khi bạn mời thành công người khác tham gia làm thành viên, bạn sẽ nhận được phần thưởng khi họ liệt kê. Điều đáng mong chờ hơn là bạn có thể nhận được phần thưởng danh sách không chỉ cho bạn bè của mình mà còn cho những thành viên mà bạn giới thiệu và những thành viên được họ giới thiệu.',
    process: 'Quy trình giới thiệu bạn bè',
    join: 'Trước khi tham gia',
    joinDescription2: 'Trước khi tham gia 88go, bạn có thể trở thành thành viên đại lý của đối phương bằng cách điền mã giới thiệu.'
        + ' Sau khi tham gia thành viên của chúng tôi, bạn cũng sẽ có cơ hội trở thành đại lý',
    becomeMember: 'Trở thành thành viên',
    becomeMemberDescription: 'Sau khi trở thành thành viên, bạn sẽ nhận được một bộ mã độc quyền, bạn có thể chia sẻ mã này cho người thân, bạn bè xung quanh và mời họ tham gia 88go.',
    becomeAgent: 'Trở thành đại lý',
    becomeAgentDescription: 'Sau khi mời người khác tham gia thành công, bạn sẽ có tư cách đại lý, thông qua kế hoạch đại lý,'
        + ' bạn sẽ có thu nhập hoa hồng và lợi nhuận giao dịch của những người bạn mời',
    bonus: 'Cách tính tiền thưởng',
    rewardCalculationRules: 'Quy tắc tính thưởng',
    algorithm: 'Số lượng vật phẩm được đăng ở mỗi cấp × phần thưởng vật phẩm duy nhất × tổng số người đã đăng ở mỗi cấp',
    listPara1: 'Tiền thưởng cho mỗi  tin đăng của hội viên là 10.000 đồng/ tin',
    level1: 'Phần thưởng cho mỗi tin đăng của bạn ở cấp bậc 1',
    level2: 'Phần thưởng cho mỗi tin đăng của bạn ở cấp bậc 2',
    level3: 'Phần thưởng cho mỗi tin đăng của bạn ở cấp bậc 3',
    listPara2: 'Phần thưởng khi bạn bè đăng tin ở cấp bậc 1 là 1.000 đồng/ tin, số lượng người tham gia tối đa là 5 người, nhưng không giới hạn về số lượng tin đăng',
    listPara3: 'Phần thưởng khi bạn bè đăng tin ở cấp bậc 2 là 500 đồng/ tin, số lượng người tham gia tối đa là 5 người, nhưng không giới hạn về số lượng tin đăng',
    listPara4: 'Phần thưởng khi bạn bè đăng tin ở cấp bậc 3 là 250 đồng/ tin, số lượng người tham gia tối đa là 5 người, nhưng không giới hạn về số lượng tin đăng',
    forExample: 'Ví dụ:',
    invite: 'Mời nhiều người',
    inviteDescription: 'Mô hình liên kết này giúp tầm ảnh hưởng và thu nhập của bạn ngày càng mở rộng. Càng mời nhiều người, bạn càng nhận được nhiều hoa hồng.',
    accounting: 'Minh bạch tài khoản',
    // eslint-disable-next-line max-len
    accountingDescription: 'Thống kê phần thưởng vào cuối mỗi tháng sẽ được phân phối tự động và các thành viên cũng có thể kiểm tra phần thưởng ngay lập tức để đảm bảo việc tính toán phân phối phần thưởng được diễn ra ngay lập tức và minh bạch.',
    profits: 'Lợi nhuận phân cấp',
    profitsDescription: 'Ngay cả khi bạn là thành viên do thành viên bạn giới thiệu giới thiệu, bạn cũng có thể nhận được phần thưởng niêm yết tương ứng.',
    joinHands: 'Chung tay chia sẻ vinh quang cuộc sống',
    joinHandsDescription: 'Mô hình liên kết này sẽ cho phép tầm ảnh hưởng và thu nhập của bạn tiếp tục mở rộng, trở thành một phần quan trọng của cộng đồng đôi bên cùng có lợi.'
        + ' 88go tin rằng mô hình cùng có lợi này có thể mang lại nhiều giá trị hơn cho mỗi thành viên và cho phép chúng ta cùng nhau tạo ra một tương lai mua nhà cho thuê tốt hơn',
    join88go: 'Gia nhập đội ngũ của 88go',
    me: 'Sở hữu',
    level: '层级',
    totalNumberOfPublishing: '刊登总数',
    singleReward: '单件奖励',
    numberOfPublishers: '刊登人数',
    rewardCalculation: '奖励计算',
    totalRewardThisMonth: '本月总奖励',
    individual: '个人',
    level1Step: '第一层',
    level2Step: '第二层',
    level3Step: '第三层',
  },
  upgrade_account: {
    title1: 'Bạn muốn đưa doanh nghiệp của mình lên một tầm cao mới?',
    // eslint-disable-next-line max-len
    description1: 'Giờ đây, với dịch vụ tài khoản nhóm của chúng tôi, bạn có thể đưa doanh nghiệp của mình lên một tầm cao mới!  Từ giờ trở đi, bạn không chỉ có thể vận hành một cửa hàng mà còn có thể mở rộng ra nhiều chi nhánh, dễ dàng quản lý hiệu quả hoạt động của từng chi nhánh và xem báo cáo chi tiết, mọi thứ đều nằm trong tầm kiểm soát của bạn!',
    learnMore: 'Tìm hiểu thêm',
    subTitle1: 'Sau khi nâng cấp tài khoản của bạn, bạn có thể',
    title2: 'Thiết lập nhiều tài khoản chi nhánh để dễ dàng quản lý',
    // eslint-disable-next-line max-len
    description2: 'Bạn có thể dễ dàng liên kết tài khoản chi nhánh với tài khoản nhóm của mình, kiểm tra tình trạng kinh doanh của từng chi nhánh và quản lý hiệu quả danh sách khách hàng của chi nhánh cũng như nhu cầu của họ.',
    subTitle2: 'Cũng có thể',
    title3: 'Đặt mục tiêu hiệu suất và xác nhận chúng ngay lập tức',
    // eslint-disable-next-line max-len
    description3: 'Các chi nhánh có thể đặt ra mục tiêu kinh doanh và phân công nhiệm vụ của riêng mình, đồng thời tính toán tỷ lệ đạt được mục tiêu dựa trên lợi nhuận.  Thông qua tài khoản, nhóm có thể kiểm tra xu hướng của từng chi nhánh mọi lúc mọi nơi, đồng thời hiểu rõ tình trạng và phương hướng hoạt động của từng cửa hàng.',
    subTitle3: 'Vẫn có thể có',
    title4: 'Báo cáo chi tiết để dễ dàng phân tích tình trạng kinh doanh',
    // eslint-disable-next-line max-len
    description4: 'Cho dù đó là báo cáo hiệu suất của từng chi nhánh, tóm tắt mục tiêu hàng tháng hay bảng nhu cầu của khách hàng, mọi thứ đều trong tầm tay bạn. Bạn có thể nắm bắt ngay mục tiêu hiệu suất và kết quả thực tế của từng chi nhánh, giúp bạn xây dựng chính sách và chiến lược bán hàng tổng thể tốt hơn.',
    title5: 'Hãy nâng cấp ngay bây giờ và trải nghiệm những khả năng vô tận!',
    // eslint-disable-next-line max-len
    description5: 'Không còn chỉ điều hành một cửa hàng duy nhất, hãy tham gia dịch vụ tài khoản nhóm của chúng tôi ngay bây giờ, dùng thử miễn phí trong một tháng và bắt đầu một chương mới trong công việc kinh doanh của bạn!',
    monthlyFee: 'Hàng tháng',
    yearFee: 'Năm',
    title6: 'Khám phá lợi ích của tài khoản nhóm - dùng thử miễn phí trong một tháng!',
    // eslint-disable-next-line max-len
    description6: 'Bắt đầu từ bây giờ, chúng tôi rất vui mừng được cung cấp cho bạn bản dùng thử miễn phí một tháng để khám phá tất cả các tính năng và lợi ích của Tài khoản nhóm của chúng tôi!  Không có rủi ro, không có giới hạn, chỉ có cơ hội để bạn khám phá và phát triển doanh nghiệp của mình!',
    notice: '※ Nếu không có nâng cấp sau thời gian dùng thử, chức năng sẽ tự động tắt.',
    subscribe: 'Đăng ký',
    notice2: '※ Nó có hiệu lực trong 30 ngày kể từ ngày đăng ký. Nếu không có hành động nào được thực hiện để hủy hoặc thay đổi gói khi hết hạn, gói tương tự sẽ được tự động gia hạn.',
  },
  mortgageCalculator: {
    enTitle: 'Mortgage Calculator',
    loanAmount: 'Khoản tiền cần vay (VND)',
    loanTerm: 'Thời gian vay (Tháng)',
    loanRate: 'Lãi suất cho vay (%)',
    loanType: 'Loại hình vay',
    average: 'Trả đều hàng tháng',
    paymentInEqual: 'Trả góp đều, lãi tính trên dư nợ giảm dần hàng tháng',
    chooseBankRate: 'Ngân hàng',
    rate: 'Lãi suất',
    monthlyInterest: 'Lãi hàng tháng',
    interestPayable: 'Tiền lãi phải trả',
    totalAmountPayable: 'Tổng phải trả',
    expand: 'Mở rộng',
    collapse: 'Thu gọn',
    detail: 'Chi tiết',
    period: 'Số kỳ',
    balancePeriod: 'Dư nợ đầu kỳ',
    principalPayable: 'Vốn phải trả',
    principalAndInterest: 'Vốn + lãi',
    total: 'Tổng',
    description: 'Sự miêu tả',
    // eslint-disable-next-line max-len
    para1: 'Chức năng tính toán khoản vay  là một tính năng được cung cấp bởi các ngân hàng thương mại tại Việt Nam để giúp khách hàng ước tính số tiền lãi và gốc cần phải trả cho khoản vay của mình.',
    para2: 'Để sử dụng tính năng tính toán khoản vay, khách hàng cần cung cấp một số thông tin cơ bản về khoản vay, bao gồm:',
    loan: 'Số tiền vay',
    para3: 'Trên cơ sở các thông tin này, tính năng sẽ tính toán và hiển thị cho khách hàng các thông tin sau:',
    list1: 'Số tiền gốc cần trả hàng tháng',
    list2: 'Số tiền lãi cần trả hàng tháng',
    list3: 'Tổng số tiền cần trả cho cả khoản vay',
    // eslint-disable-next-line max-len
    para4: 'Tính năng tính toán khoản vay ngân hàng Việt Nam là một công cụ hữu ích giúp khách hàng có thể ước tính được chi phí vay vốn trước khi quyết định vay. Tính năng này cũng giúp khách hàng có thể so sánh lãi suất và điều kiện vay vốn giữa các ngân hàng để lựa chọn được khoản vay phù hợp nhất với nhu cầu của mình.',
    para5: 'Cách sử dụng tính năng tính toán khoản vay ngân hàng Việt Nam',
    para6: 'Để sử dụng tính năng tính toán khoản vay ngân hàng Việt Nam, khách hàng có thể thực hiện theo các bước sau:',
    list4: 'Truy cập trang web hoặc ứng dụng ngân hàng di động của ngân hàng mà bạn muốn vay vốn.',
    list5: 'Tìm kiếm tính năng tính toán khoản vay.',
    list6: 'Nhập các thông tin cần thiết về khoản vay của bạn.',
    list7: 'Nhấn nút "Tính toán".',
    para7: 'Các thông tin cần nhập',
    list8: 'Số tiền vay: Số tiền mà bạn muốn vay từ ngân hàng.',
    // eslint-disable-next-line max-len
    list9: 'Lãi suất vay: Lãi suất mà bạn sẽ phải trả cho khoản vay của mình. Lãi suất vay thường được tính theo năm, nhưng bạn có thể nhập lãi suất vay theo tháng nếu tính năng cho phép.',
    // eslint-disable-next-line max-len
    list10: 'Kỳ hạn vay: Thời gian mà bạn sẽ cần trả nợ khoản vay của mình. Kỳ hạn vay thường được tính theo năm, nhưng bạn có thể nhập kỳ hạn vay theo tháng nếu tính năng cho phép.',
    para8: 'Các thông tin hiển thị',
    list11: 'Số tiền gốc cần trả hàng tháng: Số tiền gốc mà bạn cần trả hàng tháng để giảm dần khoản vay của mình.',
    list12: 'Số tiền lãi cần trả hàng tháng: Số tiền lãi mà bạn cần trả hàng tháng cho khoản vay của mình.',
    list13: 'Tổng số tiền cần trả cho cả khoản vay: Tổng số tiền gốc và lãi mà bạn cần trả cho cả khoản vay.',
    para9: 'Ghi chú',
    // eslint-disable-next-line max-len
    list14: 'Thông tin hiển thị bởi tính năng tính toán khoản vay ngân hàng Việt Nam chỉ mang tính chất tham khảo. Số tiền lãi và gốc thực tế có thể thay đổi tùy thuộc vào các yếu tố khác, chẳng hạn như tỷ lệ lạm phát và lãi suất thị trường.',
    list15: 'Bạn nên tham khảo ý kiến của chuyên viên ngân hàng để được tư vấn chi tiết về khoản vay của mình.',
  },
  files: {
    download: 'Tập tin tải về',
    downloadEn: 'Download',
    updateAt: 'Cập nhật trên:',
  },
  publish: {
    description: 'Vui lòng chọn loại danh sách nhu cầu của bạn',
    checkType: 'Xác nhận',
    information: 'Điền thông tin',
    confirmPublish: 'Xác nhận gói đăng tin',
    complete: 'Hoàn tất',
    thisAccount: '此账号',
    specifyAccount: '指定账号',
    rental: 'Đăng tin cho thuê',
    sale: 'Đăng tin mua bán',
    factoryRental: 'Đăng tin thuê nhà xưởng',
    factorySale: 'Danh sách bán nhà xưởng',
    landRental: 'Danh sách đất cho thuê',
    landSale: 'Đăng tin bán đất',
    officeRental: 'Danh sách thuê văn phòng',
    officeSale: 'Danh sách bán văn phòng',
    storeRental: 'Danh sách cho thuê cửa hàng',
    storeSale: 'Danh sách bán hàng tại cửa hàng',
    plan: 'Gói đăng tin',
    single: 'Đăng tin 1 lần',
    goToListing: 'Đi tới đăng tin',
    largeNumberOfItems: 'Có nhu cầu đăng tin với số lượng danh sách lớn không?',
    importObjectInBatches: 'Nhập các mặt hàng theo lô',
    form: {
      housingCategory: 'Loại hình nhà ở',
      housingCypePlaceholder: 'Vui lòng chọn',
      housingType: 'Kiểu nhà',
      factoryType: 'Loại hình nhà xưởng',
      landType: 'Loại đất',
      housingTypePlaceholder: 'Vui lòng chọn',
      title: 'Tiêu đề đăng tin(Số từ tối đa: 99 ký tự)',
      titlePlaceholder: 'Vui lòng nhập tiêu đề',
      titleDesPlaceholder: 'Tên dự án',
      rentalInformation: 'Thông tin cho thuê',
      saleInformation: 'Thông tin mua bán',
      price: 'Giá',
      totalPrice: 'Tổng giá',
      squareMeters: 'm2',
      household: 'hộ gia đình ',
      priceIncludingParking: 'Giá bao gồm chỗ để xe',
      priceNotIncludingParking: 'Giá chưa bao gồm chỗ để xe',
      includingParking: 'Bao gồm chỗ để xe',
      noIncludingParking: 'Chưa bao gồm chỗ để xe',
      parkingSpacePrice: 'Giá chỗ đậu xe',
      parkingArea: 'Diện tích chỗ để xe (m2)',
      parkingAreaPlaceholder: 'Vui lòng chọn loại chỗ đỗ xe',
      publicFacilities: 'Công trình công cộng',
      toBeConfirmed: 'Riêng biệt',
      rent: 'Giá thuê',
      dollarAndMonth: 'VND / tháng',
      deposit: 'Tiền cọc',
      choosePlaceholder: 'Vui lòng chọn',
      withLease: 'Đang cho thuê',
      minimumLease: 'Thời gian thuê tối thiểu',
      rentalPeriod: 'Thời hạn cho thuê',
      canMoveIn: 'Có thể chuyển đến bất cứ lúc nào',
      managementFees: 'Phí quản lý',
      enterPlaceholder: 'Vui lòng nhập',
      rentIncludes: 'Giá thuê bao gồm',
      all: 'Chọn tất cả',
      waterBill: 'Tiền nước',
      electricityBill: 'Tiền điện',
      payCable: 'Truyền hình thu phí',
      network: 'Internet',
      gasFee: 'Gas',
      manFee: 'Phí quản lý',
      housingInformation: 'Thông tin nhà ở',
      factoryInformation: 'Thông tin nhà xưởng',
      landInformation: 'Thông tin đất đai',
      officeInformation: 'Thông tin văn phòng',
      shopInformation: 'Thông tin cửa hàng',
      houseAddress: 'Địa chỉ',
      factoryAddress: 'Địa chỉ',
      landAddress: 'Địa chỉ',
      officeAddress: 'Địa chỉ',
      shopAddress: 'Địa chỉ',
      hideDoorNumber: 'Ẩn số căn hộ',
      county: 'Tỉnh / Thành',
      district: 'Quận / Huyện',
      road: 'Phường / Xã',
      addr: 'Địa chỉ',
      lane: 'Khu Phố',
      alley: 'Ngõ/hẽm',
      number: 'Số',
      of: 'Tới',
      rentalFloor: 'Tầng cho thuê',
      saleFloor: 'Tầng mua bán',
      totalFloor: 'Tổng số tầng',
      communityName: 'Tên khu chung cư',
      communityNamePlaceholder: 'Vui lòng nhập',
      legalPurpose: 'Mục đích sử dụng',
      legalPurposePlaceholder: 'Vui lòng chọn',
      orientation: 'Hướng',
      orientationPlaceholder: 'Vui lòng chọn',
      decorationLevel: 'Mức độ trang trí',
      decorationLevelPlaceholder: 'Vui lòng chọn',
      structure: 'Kết cấu hiện tại',
      structureDescription: '(Vui lòng điền trung thực. Nếu bạn không điền trung thực, chúng tôi sẽ không chịu trách nhiệm cho bất kỳ tranh chấp nào sau này)',
      layout: 'Bố cục mở',
      rooms: 'Phòng ngủ',
      hall: 'Phòng khách',
      bathrooms: 'Nhà vệ sinh',
      balcony: 'Ban công',
      area: 'Diện tích(m2)',
      houseAge: 'Năm xây dựng nhà(năm)',
      houseAgeUnknown: 'Nhà không rõ tuổi',
      areaDescription: '(Vui lòng điền trung thực. Nếu bạn không điền trung thực, chúng tôi sẽ không chịu trách nhiệm cho bất kỳ tranh chấp nào sau này)',
      lifeFunction: 'Đất đai,tiện ích xunh quanh',
      lifeFunctionDescription: 'Tổng quan tiện ích',
      nearSchool: 'Gần trường học',
      nearPark: 'Gần công viên',
      nearMarket: 'Gần chợ',
      nearStore: 'Gần siêu thị',
      nearHospital: 'Gần bệnh viện',
      nearNightMarket: 'Gần chợ đêm',
      nearConvenience: 'Gần cửa hàng tiện lợi',
      nearMRT: 'Gần tàu điện',
      starSchool: 'Trưg học hàng đầu',
      featureDescription: 'Vui lòng nhập mô tả đặc biệt',
      // eslint-disable-next-line max-len
      lifePlaceholder: 'Mô Tả:Ví dụ : 1. Thông tin diện tích đất ,Hướng , sổ đỏ v v.Địa chỉ ,vị trí khu đất ,tổng giá trị chuyển nhượng.\n2.Tiện ích xunh quanh như: gần chợ ,trường hợp ,UBND phường và trung tâm thương mại.',
      provideEquipment: 'Nội thất cơ bản',
      homeEquipment: 'Thiết bị gia dụng',
      washingMachine: 'Máy giặt',
      refrigerator: 'Tủ lạnh',
      television: 'TV',
      payTelevision: 'Truyền hình thu phí',
      waterHeater: 'Máy nước nóng',
      airConditioner: 'Máy lạnh',
      gas: 'Gas',
      microWave: 'Lò vi sóng',
      bed: 'Giường',
      closet: 'Tủ quần áo',
      sofa: 'Sofa',
      table: 'Bàn ghế',
      parking: 'Chỗ đậu xe',
      elevator: 'Thang máy',
      fireAndSecurity: 'PCCC',
      fireExtinguisher: 'Bình cứu hỏa',
      escalator: 'Thang cuốn',
      lightingDevice: 'Thiết bị chiếu sáng',
      monitoringSystem: 'Hệ thống giám sát',
      guardRoom: 'Phòng bảo vệ',
      clothesDryingYard: 'Giàn phơi quần áo',
      junkyard: 'Nơi để rác',
      gymAndRhythmClassroom: 'Phòng GYM/YOGA',
      swimmingPool: 'Hồ bơi',
      ballSportsSpace: 'Sân Bóng',
      meetingSpace: 'Phòng họp/sảnh/Phòng tiệc',
      rentalRules: 'Quy định cho thuê',
      petRestrictions: 'Hạn chế nuôi thú cưng',
      noLimit: 'Không hạn chế',
      noPets: 'Không cho phép thú cưng',
      catOnly: 'Mèo',
      dogOnly: 'Cún',
      other: 'Khác:',
      furnace: 'Bếp lửa',
      furnacePlaceholder: 'có thể/không thể',
      tax: 'Thuế',
      householdRegistration: 'Nhập tịch',
      godTable: 'Bàn thờ',
      identity: 'Xác nhận danh tính',
      officeWorker: 'Nhân viên văn phòng',
      family: 'Gia đình',
      student: 'Học sinh',
      native: 'Người trong nước',
      foreigners: 'Người nước ngoài',
      environment: 'Môi trường nhà ở',
      factoryEnvironment: 'Môi trường thực vật',
      imgNotice: 'Ảnh hỗ trợ các định dạng jpg, png và gif.Một ảnh có dung lượng giới hạn 50MB; có thể tải lên hàng loạt,ảnh đầu tiên mặc định là ảnh bìa',
      videoNotice: 'Một video được giới hạn ở 50 MB; video đầu tiên là video bìa theo mặc định.',
      layoutNotice: 'Một hình ảnh bố cục duy nhất được giới hạn ở 50 MB; hình ảnh đầu tiên là ảnh bìa theo mặc định.',
      housePicture: 'Hình ảnh',
      factoryPicture: 'Hình ảnh',
      selectFile: 'Chọn tập tin',
      notUploadedYet: 'Chưa',
      vrPicture: 'Ảnh xung quanh (VR)',
      video: 'video',
      layoutImg: 'Sơ đồ bố trí',
      houseDescription: 'Chi tiết thuê nhà',
      factoryDescription: 'Mô tả nhà xưởng',
      contentPLaceholder: 'Vui lòng nhập nội dung',
      houseDesPlaceholder: 'Nếu có: đất đai, vị trí, diện tích, thông tin pháp lý, v.v.\nVí dụ: Đất sổ đỏ 45m, đường Trần Đăng Ninh, Quận Hà Đông,',
      houseProperty: 'Đăng ký quyền sở hữu nhà ở',
      factoryProperty: 'Đăng ký quyền sở hữu nhà xưởng',
      landProperty: 'Đăng ký quyền sử dụng đất',
      propertyRightsRegistration: 'Đăng ký quyền tài sản',
      completed: 'Đăng ký quyền sở hữu đã hoàn tất',
      notCompleted: 'Hợp đồng chuyển nhượng',
      pinkBook: 'Sổ hồng',
      buyAndSell: 'Mua bán tài liệu viết tay',
      legalPurposesOfaAd: 'Mục đích quảng cáo này:',
      buildingAreaOfAd: 'Diện tích xây dựng trên quảng cáo này:',
      rentInAd: 'Tầng cho thuê trên quảng cáo này:',
      notFilled: 'Chưa điền thông tin',
      housePropertyDescription: 'Nếu thông tin trên không chính xác hoặc dữ liệu không được hiển thị, bạn vui lòng:',
      housePropertyCheck: '1.Kiểm tra xem thông tin địa chỉ nhà',
      housePropertyUpload: '2.Tải lên bản sao /bản sao trực tuyến của quyền sở hữu tòa nhà sau đó sẽ xác minh và bổ sung thông tin đầy đủ cho bạn (thông tin tải lên chỉ nhằm mục đích xác minh)',
      buildingTitlePhotocopy: 'Bản sao tên tòa nhà',
      houseManagement: 'Ban quản lý',
      addObjectToEscrow: 'Tăng thêm người quản lý?',
      escrow: 'Thêm người quản lý',
      yes: 'Có',
      no: 'Không',
      can: 'Cho phép',
      not: 'Không cho phép',
      next: 'Bước tiếp theo',
      save: 'Lưu dữ liệu',
      preview: 'Xem trước',
      returnToEdit: 'Quay lại chỉnh sửa',
      bargainingPrice: 'Thương lượng',
      toBeLeased: 'Đang chờ thuê',
      layoutDiaram: 'Sơ đồ',
      modifyAddrAlert: ' giờ, số lần sửa đổi còn lại: ',
      usePartitions: 'Dùng phân vùng',
      factoryRegistration: 'Đăng ký nhà xưởng',
      companyRegistration: 'Đăng ký công ty',
      dividedIntoCompartments: 'Ngăn',
      suitableForIndustry: 'Các ngành áp dụng',
      disabledIndustry: 'Các ngành bị cấm',
      buildingStructure: 'Kết cấu',
      floorLoad: 'Tải trọng sàn',
      asphaltRoad: 'Đường nhựa',
      concreteRoad: 'Đường Bê tông',
      alleyStreet: 'Đường hẻm',
      widthOfTheFrontRoad: 'Khoảng cách đến mặt đường',
      buildingHeightLimit: 'Xây dựng giới hạn chiều cao(mét)',
      unloading: 'Xuống hàng',
      passengerElevator: 'Thang máy chở khách(Số lượng)',
      freightElevator: 'Thang máy chở hàng(Số lượng)',
      num: 'Số lượng',
      totalLandArea: 'Tổng diện tích đất',
      totalFactoryArea: 'Tổng diện tích',
      constructionArea: 'Diện tích tòa nhà',
      specification: 'Đặc điểm kỹ thuật(mét)',
      meter: 'mét',
      mt: 'tấn',
      width: 'Chiều dọc',
      verticalWidth: 'Chiều rộng dọc',
      verticalDepth: 'Chiều sâu',
      height: 'Cao',
      firstFloorHeight: 'Chiều cao tầng một',
      deep: 'Chiều sâu',
      baseArea: 'Diện tích đất',
      purpose: 'Công dụng',
      infrastructure: 'Cơ sở hạ tầng',
      landStatus: 'Hiện trạng đất đai',
      landEnv: 'Môi trường',
      landPhotos: 'Hình ảnh',
      landDescription: 'Nguồn gốc đất',
      // eslint-disable-next-line max-len
      landDescriptionPlaceholder: 'Nếu có : tên đất đai ,đất dự án ,quy cách đất ,thời hạn sử dụng và những thông tin pháp lý vv.\nVí dụ : khu dân cư Lê Phong ,phường Bình Chuẩn 42,Thành Phố Thuận An,Tỉnh Bình Dương .\nQuy cách đất 4 x16.7=66.8 m2.đất ở thổ cư 100%\nThời hạn sử dụng đất : Lâu dài .',
      saleBySplits: 'bán theo chia tách？',
      mainBuilding: 'Tổng diện tích tòa nhà chính',
      shareParts: 'Tổng diện tích các phần dùng chung',
      ancillaryBuildings: 'Tổng diện tích các công trình phụ trợ',
      // eslint-disable-next-line max-len
      formWarning: 'Vui lòng điền các thông tin xác thực.Chúng tôi sẽ không chịu trách nhiệm về những tranh chấp tiếp theo bạn hoàn toàn phải chịu trách nhiệm với thông tin trên',
      officeCategory: 'Loại hình văn phòng',
      officeType: 'Kiểu văn phòng',
      useArea: 'Diện tích sử dụng',
      ownershipCertificate: 'Giấy chứng nhận quyền sở hữu đất',
      workstation: 'Số lượng chỗ tối đa',
      unit: 'người',
      carParking: 'Chỗ đậu xe ô tô(VND / tháng)',
      motorParking: 'Chỗ để xe máy(VND / tháng)',
      industriesAndCommercialRegistration: 'Đăng ký doanh nghiệp',
      handoverOfHouse: 'Bàn giao nhà hiện trạng',
      surroundingLiving: 'Chức năng sống xung quanh',
      surroundingLivingPlaceholder: 'Hãy nhập các chức năng sống xung quanh',
      officeEquipment: 'Thiết bị văn phòng',
      otherEquipment: 'Các thiết bị khác',
      officeEnv: 'Môi trường',
      officePhotos: 'Hình ảnh',
      shopCategory: 'Loại hình cửa hàng',
      shopType: 'Kiểu cửa hàng',
      shopInfo: 'Thông tin cửa hàng',
      operatingStatus: 'Trạng thái kinh doanh',
      customerFlow: 'Nguồn khách hàng',
      shopEquipment: 'Thiết bị cửa hàng',
      shopEnv: 'Môi trường cửa hàng',
      shopPhotos: 'Lưu trữ ảnh',
      basicEquipment: 'Thiết bị cơ bản',
      handlingEquipment: 'Thiết bị xếp dỡ',
    },
    errMsg: {
      titleErr: 'Tiêu đề không được để trống',
      cateErr: 'Vui lòng chọn loại hình',
      typeErr: 'Vui lòng chọn tình trạng',
      priceErr: 'Tiền thuê không được để trống',
      depositErr: 'Tiền cọc không được để trống',
      minLeaseErr: 'Thời hạn thuê tối thiểu không được để trống',
      rentalPeriodErr: 'Thời hạn cho thuê không được để trống',
      mngFeeErr: 'Phí quản lý không được để trống',
      chooseErr: 'Vui lòng chọn',
      saleFloorErr: 'Tầng bán không được để trống',
      totalFloorErr: 'Tổng số tầng không được để trống',
      emptyErr: 'Không được để trống',
      squareErr: ' Không được để trống',
      rentalFloorInvalidErr: ' Không được vượt quá tổng số tầng',
      saleFloorInvalidErr: ' Không được vượt quá tổng số tầng',
      imageErr: 'Vui lòng tải lên ít nhất 2 và tối đa 25 ảnh',
      videoErr: 'Tải lên tối đa 3 video',
      layoutErr: 'Tải lên tối đa 3 hình ảnh',
      singlePriceErr: ' Nhập số tiền',
      minPriceLargerErr: ' Số tiền tối thiểu không lớn hơn số tối đa',
      maxPriceSmallerErr: ' Số tiền tối đa không nhỏ hơn số tối thiểu',
      addressErr: 'Hãy nhập địa chỉ',
      unitPriceErr: 'Vui lòng nhập tổng giá và diện tích',
      less30CharactersErr: 'Vui lòng nhập ít nhất 30 ký tự',
    },
    selectExistingPlan: 'Chọn kế hoạch đăng tin hiện có',
    buyPlan: 'Mua gói niêm yết',
    useExistingPlan: 'Sử dụng phương án hiện có',
    singlePosting: 'Đăng tin đơn',
    multiplePlans: 'Các phương án',
    averagePrice: 'Đơn giá bình quân',
    priceUnit: '₫/bài',
    singlePostingRevise: 'Sửa đổi 3 lần trong vòng 72 giờ',
    singlePostingDay: 'Thời hạn bài đăng :30 ngày',
    noExposure: 'Không tăng cường hiển thị',
    selectPlan: 'Vui lòng chọn gói',
    remainingOfItems: 'Số bài đăng còn lại',
    items: 'Bài',
    remainingOfDays: 'Thời hạn gói còn lại:',
    days: 'Ngày',
    enhancedExposure: 'Tăng cường hiển thị',
    valueAddedServices: 'Tăng cường dịch vụ',
    updateSort: 'Cập nhật sắp xếp',
    manualUpdate: 'Cập nhật thủ công',
    manualUpdatesDescription: '(Bấm thủ công để cập nhật sắp xếp)',
    dollar: 'nhân dân tệ',
    tenThousand: 'Vạn',
    point: ' Điểm',
    bount: 'Lần',
    automaticUpdates: 'Cập nhật tự động',
    automaticUpdatesDescription: '(Hệ thống tự động cập nhật sắp xếp)',
    regularUpdates: 'Đặt thời gian cập nhật',
    regularUpdatesDescription: '(Tùy chỉnh số lần và thời gian cập nhật)',
    daily: 'Hàng ngày',
    timeToUpdate: 'Cập nhật theo giờ',
    hour: 'Giờ',
    minute: 'Phút，',
    update: 'Cập nhật',
    adServices: 'Dịch vụ quảng cáo',
    preferred: 'Ưa thích',
    preferredDescription: '(Trang chủ”Ưa thích”khu)',
    recommended: 'Đề xuất',
    recommendedDescription: '(Danh sách”đề xuất”khu)',
    labelingService: 'Đánh dấu',
    pin: 'Ghim đầu trang',
    pinDescription: '(Đánh dấu nổi bật đầu trang)',
    choice: 'Chọn lọc',
    choiceDescription: '(Đánh dấu nổi bật chọn lọc)',
    expedtedTag: 'Đánh dấu gấp',
    expedtedTagDescription: '(Đánh dấu nổi bật gấp)',
    webExposure: 'Phiên bản web',
    mobileExposure: 'Phiên bản di động',
    exposureArea: 'Độ hiển thị',
    effectiveTime: 'Thời gian hiệu lực',
    validityPeriod: 'Thời hạn hiệu lực',
    currentlyOwned: 'Hiện tại đang sở hữu',
    exposureNotice: 'Lưu ý: Hiệu ứng phơi sáng có thể được mua nhiều lần nhưng chúng sẽ ghi đè các hiệu ứng phơi sáng đã mua trước đó! Nên đánh giá trước khi mua!',
    purchase: 'Mua',
    effectiveAfterPayment: 'Có hiệu lực ngay sau khi thanh toán',
    exposurePlan: 'Gói hiển thị',
    customAmount: 'Số tiền tùy chỉnh',
    recommended88go: '88go đề nghị',
    exceptedRank: 'Xếp hạng dự kiến ​​hôm nay',
    customAmountPlaceholder: 'Vui lòng nhập số tiền tùy chỉnh',
    lessThan30: '(Số điểm tối thiểu không dưới 23.000)',
    exposureDays: 'Số ngày hiển thị',
    customTime: 'Thời gian tùy chỉnh',
    customTimePlaceholder: 'Vui lòng nhập thời gian tùy chỉnh',
    details: 'Chi tiết',
    amountsPayable: 'Số tiền phải trả:',
    publishNow: 'Đăng tin ngay bây giờ',
    publishSuccess: 'Đăng tin thành công',
    goToHousingInfo: 'Đi tới thông tin danh sách',
    publishManagement: 'Quản lý đăng tin',
    warningNotice: '*Trang này là trang danh sách xem trước, không phải trang đăng tin chính thức. Hãy nhớ nhấp vào đăng tin để hoàn tất quá trình',
    noPlan: 'Hiện tại chưa có kế hoạch mua',
    estimated: 'Thời gian xuất bản dự kiến',
    estimatedNotice: 'Nếu không chọn thời gian vào ngày hiện tại thì giờ hiện tại cộng thêm một giờ sẽ được sử dụng.',
    wantToAddObject: 'Thêm đối tượng mới',
  },
  memberCenter: {
    home: 'Trang chủ thành viên',
    account: 'Tài khoản',
    message: 'Quản lý tin nhắn',
    history: 'Lịch sử',
    promotions: 'Sự kiện quảng bá',
    transaction: 'Quản lý giao dịch',
    advertisement: 'Quản lý quảng cáo',
    signOut: 'Đăng xuất',
    edit: 'Chỉnh sửa hồ sơ',
    helloMember: 'Xin chào',
    switchIdentity: 'Chuyển đổi danh tính',
    myAgent: 'Đại lí của tôi',
    promotionUrl: 'URL khuyến mãi',
    copy: 'Sao chép',
    vipTitle: 'Hỗ trợ đăng tin giúp bạn chốt giao dịch một cách dễ dàng！',
    vipDes: 'Tận hưởng nhiều tin đăng và nhận được sự trợ giúp miễn phí từ nhân viên để đăng tin các đơn hàng',
    numberOfRegistered: 'Số người đăng ký',
    numberOfPublishers: 'Đăng bởi',
    numberOfPublications: 'Số lượng ấn phẩm',
    signUpBonus: 'Đăng ký thưởng',
    publicationBonus: 'Tiền thưởng xuất bản',
    promotionTotal: 'Tổng số người thăng cấp',
    promotionTotalBonus: 'Tổng tiền thưởng khuyến mãi',
    startTime: 'Thời gian bắt đầu',
    endTime: 'Thời gian kết thúc',
    downline: 'Tên tuyến dưới',
    publishStatus: 'Trạng thái công bố',
    effectiveQuantity: 'Số lượng hợp lệ',
    invalidQuantity: 'Số lượng không hợp lệ',
    registrationTime: 'Thời gian đăng ký',
    objectName: 'Tên đối tượng',
    invalidReason: 'Lý do không hợp lệ',
    peopleUnit: 'Số người',
    dollar: 'nhân dân tệ',
    totalIncome: 'Tổng thu nhập trong tháng',
    totalCommission: 'Tổng hoa hồng',
    myPoints: 'Điểm của tôi',
    recharge: 'Nạp',
    storedRecord: 'Ghi nhận nạp',
    basicPlan: 'Gói cơ bản',
    items: 'Tin',
    purchasePlan: 'Mua',
    projectManagement: 'Quản lý gói',
    recentFavorites: 'Mục yêu thích gần đây',
    more: 'Xem thêm',
    noFavorite: 'Hiện tại chưa có mục yêu thích nào',
    see: 'Tham khảo',
    personalInfo: 'Thông tin cá nhân',
    invoiceManagement: 'Quản lý hóa đơn',
    changePhoto: 'Thay đổi',
    changeSubAccountPhoto: 'Thay đổi',
    photoLimit: '*Tải lên hình đại diện của riêng mình, vui lòng tải lên ảnh cận cảnh, toàn mặt với các đặc điểm khuôn mặt rõ ràng<br>'
        + '*Hình ảnh được giới hạn ở định dạng jpg, gif, png, nhỏ hơn 1M',
    workPhotoLimit: '*Hình ảnh được giới hạn ở định dạng jpg, gif, png, nhỏ hơn 1M',
    changeGroupPhoto: 'Thay đổi',
    form: {
      name: 'Họ tên',
      namePlaceholder: 'Vui lòng nhập họ tên',
      gender: 'Giới tính',
      genderPlaceholder: 'Vui lòng chọn giới tính',
      phoneNumber: 'Số điện thoại',
      change: 'Thay đổi',
      mailPlaceholder: 'Vui lòng nhập email',
      pwd: 'Mật khẩu',
      set: 'Đã thiết lập',
      notSet: 'Không được thiết lập',
      changePwd: 'Đổi',
      link: 'Liên kết',
      unlink: 'Hủy liên kết',
      rating: 'Chấm điểm',
      qrcodeDownload: 'Tải xuống mã QR',
      realName: 'Tên thật',
      certificationStatus: 'Trạng thái chứng chỉ',
      notCertified: 'Chưa chứng nhận',
      certificationSuccess: 'Đã xác minh',
      idCard: 'CCCD/Chứng ming thư',
      idCardPlaceholder: 'Vui lòng nhập số CCCD/Chứng ming thư',
      approvalDate: 'Ngày phê duyệt',
      approvalDatePlaceholder: 'Vui lòng nhập ngày phê duyệt',
      introducer: 'Người giới thiệu',
      idCardPicture: 'Hình ảnh chứng minh nhân dân',
      certificateType: 'Loại chứng chỉ',
      certificatePicture: 'Ảnh chứng chỉ',
      certificatePicturePlaceholder: 'Vui lòng tải lên tập tin hình ảnh',
      saleperson: 'Nhân viên kinh doanh bất động sản',
      agent: 'Đại lý bất động sản',
      certificatePictureNotice: 'Hãy đảm bảo rằng tên, số chứng chỉ, số CMND, v.v. của bạn được hiển thị rõ ràng và bao gồm thông tin mã vạch quan trọng. Không thể lớn hơn 50M.',
      idNumberRemark: 'Chúng tôi bảo vệ nghiêm ngặt thông tin cá nhân của bạn',
      idNumberContent: 'Xem mô tả để biết chi tiết.',
      accountOwnership: 'Sở hữu tài khoản',
      companyAccount: 'Tài khoản công ty',
      branchAccount: 'Tài khoản chi nhánh',
      other: 'Khác',
      emergencyContact: 'Người liên lạc khẩn cấp',
      contactIdentity: 'Danh tính người lên hệ',
      contactIdentityPlaceholder: 'Vui lòng chọn người liên hệ',
      contactPhone: 'Số điện thoại người liên hệ',
      brokerageName: 'Tên môi giới',
      brokerageNamePlaceholder: 'Vui lòng nhập tên môi giới',
      workArea: 'Khu vực làm việc',
      company: 'Chi nhánh',
      companyName: 'Tên công ty',
      belongToGroup: 'Bạn có phải là thành viên chi nhánh của nhóm?',
      joinToGroup: 'Tham gia',
      branchName: 'Tên chi nhánh',
      hideHouseDetail: 'Ẩn thông tin công ty trên trang chi tiết ngôi nhà và trang cửa hàng',
      position: 'Chức vụ',
      positionPlaceholder: 'Vui lòng chọn một vị trí',
      branchType: 'Loại chi nhánh',
      directSale: 'Cửa hàng trựt tiếp',
      franchise: 'Cửa hàng nhượng quyền',
      updateAccountPlan: 'Bạn sở hữu nhiều chi nhánh và lo lắng về cách quản lý chúng?',
      updateBtnText: 'Nâng cấp',
      upgradeAccountTitle: 'Bạn đang lo lắng về cách quản lý chi nhánh của mình?',
      currentPlan: 'Kế hoạch nâng cấp hiện tại',
      noPlan: 'Không có kế hoạch',
      subscriptionTime: 'Thời gian đăng ký',
      settlementType: 'Loại hình giải quyết',
      projectFee: 'Phí chương trình',
      subscriptionStatus: 'Trạng thái',
      updateAccountDes: 'Chúng tôi cung cấp gói nâng cấp tài khoản phù hợp với nhóm! Cho phép bạn dễ dàng quản lý các chi nhánh của mình và theo dõi tình trạng kinh doanh theo thời gian thực.',
      storeName: 'Tên cửa hàng',
      storeNamePlaceholder: 'Vui lòng nhập tên cửa hàng',
      serviceSlogan: 'Khẩu hiệu dịch vụ',
      serviceSloganPlaceholder: 'Vui lòng khẩu hiệu dịch vụ',
      serviceArea: 'Khu vực phục vụ',
      addService: 'Thêm khu vực dịch vụ mới (tối đa 3 mục)',
      familiarCommuntity: 'Làm quen với cộng đồng',
      experience: 'Kinh nghiệm kinh doanh',
      year: 'Năm',
      month: 'Tháng',
      to: 'để',
      in: 'Tại',
      branch: 'chi nhánh',
      takeOffice: 'bổ nhiệm',
      addExperience: 'Thêm trải nghiệm mới (tối đa 3 mục)',
      certificateHonors: 'Chứng dự',
      yearOfExperience: 'Năm làm việc',
      certName: 'Tên chứng chỉ',
      get: 'lấy',
      addCert: 'Thêm chứng chỉ mới (tối đa 3 mục)',
    },
    notice: 'Đề phòng:',
    noticeParagraph1: '1.88go ngày xử lý hóa đơn: Nó sẽ được gửi trong vòng 15 ngày làm việc sau khi thành viên gửi giá trị.',
    noticeParagraph2: '2. Nếu bạn vẫn cần đăng ký hóa đơn giấy, vui lòng nhấp vào để đăng ký giao hóa đơn giấy trong quá trình đặt cọc.'
        + ' Hóa đơn đã điền đầy đủ sẽ được gửi đi sau 7 ngày làm việc sau khi hoàn tất đơn đăng ký.',
    noticeParagraph3: '3.Để yêu cầu hóa đơn ba liên, vui lòng liên hệ',
    noticeParagraph4: 'Cung cấp tiêu đề, người biên tập và địa chỉ gửi hóa đơn và chúng tôi sẽ có người tận tâm xử lý việc đó cho bạn.',
    save: 'Lưu dữ liệu',
    invoiceList: 'Danh sách hóa đơn',
    changePhoneDescription: 'Vui lòng nhập số điện thoại mới của bạn bên dưới',
    phone: 'Số điện thoại',
    phonePlaceholder: 'Vui lòng nhập số điện thoại',
    sendVerficationCode: 'Gửi mã xác minh',
    sendCode: 'Mã xác minh gồm 4 chữ số đã được gửi tới',
    verify: 'Xác minh',
    verifySuccess: 'Số điện thoại được thay đổi thành công, bạn sẽ được chuyển hướng trở lại trang quản lý tài khoản',
    confirm: 'Xác nhận',
    changePwd: 'Đổi mật khẩu',
    changePwdDescription: 'Vui lòng nhập mật khẩu mới của bạn vào phía dưới',
    oldPwd: 'Mật khẩu cũ',
    oldPwdPlaceholder: 'Vui lòng nhập mật khẩu cũ',
    newPwd: 'Mật khẩu mới',
    newPwdPlaceholder: 'Vui lòng nhập mật khẩu từ 8 đến 12 ký tự bao gồm các ký tự chữ và số',
    confirmNewPwd: 'Xác nhận mật khẩu mới',
    changeSuccessDescription: 'Đã đổi mật khẩu thành công, vui lòng đăng nhập lại',
    websiteNotification: 'Thông báo',
    houseQA: 'Hỏi đáp nhà ở',
    editMessage: 'Chỉnh sửa tin nhắn',
    deleteMessage: 'Xóa tin nhắn',
    impeach: 'Báo cáo',
    matchingObject: 'Đối tượng phù hợp',
    previous: 'Trang trước',
    next: 'Trang tiếp theo',
    chooseAll: 'Chọn tất cả',
    readSelected: 'Tin nhắn đã xem',
    deleteSelected: 'Xóa',
    // eslint-disable-next-line max-len
    matchingText: 'Các thành viên của 88go thân mến,\nCảm ơn bạn đã ủng hộ và quan tâm đến trang web cho thuê của chúng tôi.\nChúng tôi đã hòan thành kết nối dựa theo nhu cầu của bạn',
    recommendObject: 'Đề nghị',
    idealDes: 'Hãy tham khảo các mặt hàng được đề xuất của chúng tôi để tìm thấy mặt hàng lý tưởng của bạn!',
    historyType: 'kiểu',
    caseNumber: 'Mã số',
    caseNumberPlaceholder: 'Vui lòng nhập',
    productName: 'Công ty / Sản phẩm',
    productNamePlaceholder: 'Vui lòng nhập',
    questionTime: 'Thời gian câu hỏi',
    questionTimePlaceholder: 'Vui lòng nhập ngày',
    search: 'Tìm kiếm',
    clear: 'Xóa nội dung',
    title: 'Tiêu đề',
    publisher: 'Người đăng tin',
    publishType: 'Kiểu tin',
    status: 'Trạng thái',
    rent: 'Cho thuê',
    sale: 'Mua bán',
    noReply: 'Chưa trả lời',
    replied: 'Đã trả lời',
    viewDetail: 'Kiểm tra chi tiết',
    check: 'Kiểm tra',
    yourQuestion: 'Câu hỏi của bạn',
    userQuestion: 'Câu hỏi của người dùng',
    yourImpeachContent: 'Nội dung báo cáo của bạn',
    noProcessed: 'Chưa xử lý',
    processing: 'Đang xử lý',
    closed: 'Đã xử lý',
    publisherReply: 'Trả lời của người đăng tin',
    customerReply: 'Trả lời dịch vụ khách hàng',
    reply: 'Trả lời',
    replayLabel: 'Vui lòng nhập nội dung trả lời của bạn',
    contentPLaceholder: 'Vui lòng nhập nội dung',
    sendReply: 'Gửi trả câu lời',
    previousPage: 'Quay lại trang trước',
    watchHistory: 'Lịch sử xem',
    favoriteObjects: 'Mục yêu thích',
    transactionRecords: 'Danh sách giao dịch nhà',
    establishmentTime: 'Thời gian lập trường hợp',
    sort: 'Sắp xếp',
    houseType: 'Loại hình nhà ở',
    area: 'Khu vực',
    noticeText: '*Hồ sơ giao dịch này chỉ cung cấp hồ sơ giao dịch nhà ở với 88go',
    caseType: 'Loại trường hợp',
    propertyType: 'Loại hình nhà ở',
    price: 'Số tiền',
    personalManagement: 'Quản lý cá nhân',
    performanceReport: 'Báo cáo hiệu suất',
    salesPersonReport: 'Báo cáo lực lượng bán hàng',
    accountManagement: 'Quản lý tài khoản',
    overview: 'Tổng quan về đại lý',
    collectionAccount: 'Tài khoản nhận',
    paymentRecords: 'Danh sách khoản nhận',
    changeAccount: 'Thay đổi tài khoản',
    totalPerformance: 'Tổng hiệu suất',
    plantformTotalCommission: 'Tổng hoa hồng nền tảng',
    myCommission: 'Hoa hồng của tôi',
    startMonth: 'Tháng bắt đầu',
    startMonthPlaceholder: 'Vui lòng nhập tháng',
    level: 'cấp',
    people: 'Số người',
    accountWarningNotice: '*Số đầu tiên trong tài khoản là tài khoản thu mặc định, nếu bạn cần sửa đổi tài khoản, vui lòng kéo và thay đổi thủ công',
    addAccount: 'Thêm tài khoản mới',
    deleteAccount: 'Xóa tài khoản',
    agentRegulations: 'Quy định ại lý',
    addAccountDescription: 'Vui lòng thêm tài khoản ngân hàng của bạn bên dưới',
    bank: 'Ngân hàng',
    choosePlaceholder: 'Vui lòng chọn',
    chooseExprYear: 'Năm hết hạn',
    chooseExprMonth: 'Tháng hết hạn',
    breanchName: 'Tên chi nhánh',
    bankAccount: 'Tài khoản ngân hàng',
    enterPlaceholder: 'vui lòng nhập',
    cashManagement: 'Quản lý dòng tiền',
    pointsManagement: 'Quản lý điểm',
    plansManagement: 'Quản lý gói',
    paymentManagement: 'Quản lý thanh toán',
    recharged: 'Nạp tiền',
    expenses: 'Chi tiêu',
    transactionTime: 'Thời gian giao dịch',
    type: 'Loại',
    remark: 'Chú thích',
    paymentStatus: 'Trạng thái thanh toán',
    revenue: 'Thu nhập',
    remitted: 'Chuyển tiền',
    point: 'Điểm',
    points: 'Điểm',
    rechargedSuccess: 'Nạp tiền thành công, số điểm còn lại：',
    palnType: 'Loại gói',
    planName: 'Tên gói',
    purchaseTime: 'Thời gian mua',
    expireTime: 'Sự chín chắn',
    numberOfPlans: 'Số lượng kế hoạch',
    remainingTime: 'Thời gian còn lại',
    continue: 'Tôi muốn gia hạn',
    checkNotice: 'Vui lòng xác nhận xem gói đã chọn có đúng không',
    choosePlan: 'Chọn gói',
    period: 'Thời hạnpay_type thành viên',
    day: 'Ngày',
    planPoint: ' Gói điểm',
    additional: 'Dịch vụ đại lý mua hàng',
    noAdditionalPurchase: 'Không mua thêm',
    additionalPurchase: 'Mua thêm, số lượng mua',
    purchaseCount: 'Số lượng mua',
    additionalPurchaseService: 'Dịch vụ bổ sung',
    notEnoughPoints: 'Không đủ điểm? Đi nạp tiền?',
    confirmPayment: 'Xác nhận thanh toán',
    deleteCard: 'Xóa thẻ',
    creditCardsAcceptedOnly: 'Chấp nhận thẻ tín dụng',
    creditCard: 'Thẻ tín dụng',
    addCreditCard: 'Thêm thẻ tín dụng mới',
    addNewCard: 'Thêm thẻ mới',
    cardholderName: 'Tên chủ thẻ',
    creditCardNumber: 'Số thẻ tín dụng',
    expiration: 'Ngày hết hạn thẻ tín dụng',
    securityCode: 'Mã bảo mật thẻ tín dụng',
    securityCodePlaceholder: 'Vui lòng nhập mã bảo mật',
    nameplaceholder: 'Vui lòng nhập họ tên',
    rentalPlan: 'Gói cho thuê',
    remainingTransactions: 'Tổng số còn lại',
    publishedBySecretary: 'Số lượng tin đăng của đội ngũ chuyên môn',
    notUse: 'Số lượng thư ký đăng tin miễn phí chưa được sử dụng',
    salePlan: 'Đăng tin mua bán',
    listing: 'Danh sách tin',
    listingDescription: '(Tại hiển thị nhiều nhất 4 mục mới nhất)',
    publishManagement: 'Quản lý tin đăng',
    businessManagement: 'Quản lý kinh doanh',
    publish: 'Đăng tin',
    noPublish: 'Hiện tại không có bất động sản nào được liệt kê',
    remaining: 'Số giao dịch còn lại',
    purchaseAgain: 'Mua lại',
    subscribeAgain: 'Đăng ký lại',
    housesClosed: 'Đóng',
    removedHouses: 'Đã giao dịch',
    editHouse: 'Không có danh sách',
    numberOfListing: 'Số lượng tin đăng',
    numberOfSinglePublications: 'Số lượng tin đăng đơn',
    publishAll: 'Đăng tất cả',
    singlePosting: 'Đăng tin đơn',
    rentalPlanPublication: 'Gói đăng tin cho thuê',
    salePlanPublication: 'Gói đăng tin mua bán',
    publicationNumber: 'Mã số đăng tin',
    publicationTime: 'Thời gian đăng tin',
    housingInformation: 'Thông tin',
    hoisingPrice: 'Thành tiền',
    numberOfVisitors: 'Số lượt xem',
    timeRemaining: 'Thời gian tin còn lại',
    operate: 'Thao tác',
    closeHousing: 'Đóng',
    dealHousing: 'Giao dịch',
    improveEffect: 'Nâng cao hiệu quả',
    improveEffectDes: 'Nếu bạn chọn cải thiện hiệu ứng, các hiệu ứng hiện có của bạn sẽ không còn hiệu lực.<br>Bạn có muốn xác nhận hiệu ứng không?',
    updateSort: 'Cập nhật sắp xếp',
    currentNumberOfPublications: 'Số lượng tin đăng hiện tại',
    remainingListing: 'Tin đăng còn lại',
    proPlan: 'Gói chuyên nghiệp',
    closureReason: 'Lí do đóng',
    publishHouse: 'Đăng',
    removedTime: 'Thời gian xóa giao dịch',
    publishAgain: 'Đăng tin lại',
    numberOfTransactions: 'Số lượng giao dịch',
    renew: 'Gia hạn',
    sortPlan: 'Kế hoạch sắp xếp',
    sortTime: 'Thời gian sắp xếp',
    adPlan: 'Gói quảng cáo',
    adTime: 'Thời gian quảng cáo',
    labelingPlan: 'Kế hoạch ghi nhãn',
    labelingTime: 'Thời gian ghi nhãn',
    enhanceExposure: 'Tăng cường hiển thị',
    exposureArea: 'Khu vực hiển thị',
    exposureType: 'Phương pháp tiếp xúc',
    exposureTime: 'Thời gian hiển thị',
    valueAddedServices: 'Dịch vụ nâng cao',
    valueAddedCategory: 'Hạng mục nâng cao',
    valueAddedOption: 'Tùy chọn bổ sung',
    valueAddedTime: 'Tăng thêm giời gian',
    publicationDate: 'Đăng tin',
    releaseDate: 'Đăng tin',
    estimated: 'Dự kiến',
    listingStatus: 'Trạng thái tin',
    publicationTitle: 'Tiêu đề đăng tin',
    deleteListing: 'Xóa tin đăng',
    editListing: 'Chỉnh sửa',
    editHouseDetail: 'Chỉnh sửa',
    currentEffects: 'Hiệu quả hiện có',
    storeManagement: 'Quản lý cửa hàng',
    certification: 'Chứng nhận',
    workData: 'Tài liệu',
    uploadCertification: 'Tải lên chứng chỉ trình độ chuyên môn',
    waitingForReview: 'Đợi kiểm duyệt',
    reviewSuccessful: 'Kiểm duyệt thành công',
    step1Des: '*Sau khi bạn gửi thông tin, nhân viên dịch vụ khách hàng sẽ xem xét trong vòng 24 giờ, bạn có thể kiểm tra kết quả xét duyệt ở chứng nhận môi giới',
    submit: 'Xác nhận gửi',
    submissionSuccess: 'Thông tin đã được gửi thành công và sẽ được nhân viên dịch vụ khách hàng xem xét trong vòng 24 giờ. Vui lòng đợi',
    retrunToMemberHome: 'Quay lại trang chủ',
    emergencyNotice1: '1. Người liên hệ khẩn cấp chỉ là kênh thông báo cho thành viên và không thể thay mặt thành viên thực hiện quyền tài khoản',
    emergencyNotice2: '2. Thông báo cho người liên hệ khẩn cấp được coi là đã thông báo cho thành viên nên nếu thông tin liên hệ có thay đổi thì cần cập nhật ngay.'
        + ' Nếu người liên hệ khẩn cấp từ chối trả lời, không hỗ trợ thông báo cho thành viên hoặc không liên lạc được dẫn đến thành viên không nhận được thông báo,'
        + 'thành viên sẽ hoàn toàn chịu trách nhiệm',
    storeData: 'Dữ liệu cửa hàng',
    storeQA: 'Hỏi đáp về cửa hàng',
    flyers: 'Tờ rơi',
    storeInfo: 'Thông tin cửa hàng',
    businessExperience: 'Kinh nghiệm kinh doanh',
    introduce: 'Tự giới thiệu',
    activate: 'Kích hoạt',
    isActivateStore: 'Có bật cửa hàng hay không?',
    qaNumber: 'Mã số hỏi đáp',
    questionAccount: 'Tài khoản đặt câu hỏi',
    choose: 'Chọn',
    selectAll: 'Chọn tất cả',
    createFlyers: 'Tờ rơi',
    flyersProductionRecord: 'Kỷ lục',
    checked: 'Đã chọn',
    flyersListing: 'Đăng tin nhà ở',
    productionDate: 'Ngày sản xuất',
    fileName: 'Tên file',
    view: 'xem',
    download: 'tải về',
    delete: 'xóa bỏ',
    subAccount: 'Quản lý tài khoản phụ',
    total: 'Tổng cộng',
    subAccountGroup: 'Tài khoản phụ nhóm',
    addSubAccount: 'Thêm tài khoản phụ',
    subAccountInfo: 'Thông tin tài khoản phụ',
    serialNumber: 'Mã số',
    lastLoginTime: 'Lần đăng nhập cuối cùng',
    editAccount: 'Chỉnh sửa',
    securityCodeOverErr: 'Lỗi mã bảo mật',
    securityCodeEmptyErr: 'Mã bảo mật không được để trống',
    cardholderNameEmptyErr: 'Tên chủ thẻ không được để trống',
    cardNumberEmptyErr: 'Số thẻ tín dụng không được để trống',
    cardTypeError: 'Lỗi định dạng số thẻ tín dụng',
    advertisementRecord: 'Danh sách quảng cáo',
    advertisementApplicationRecord: 'Kiểm duyệt quảng cáo',
    AdsRunning: 'Đang chạy quảng cáo',
    closeAds: 'Đóng quảng cáo',
    adsArea: 'Vị trí quảng cáo',
    adsInfo: 'Thông tin quảng cáo',
    numberOfDaysRemaining: 'Còn lại',
    adsPlan: 'Gói quảng cáo',
    sidebarNotice: 'Vui lòng tải lên 199px * 600px cho quảng cáo thanh bên trang chủ',
    popupsNotice: 'Vui lòng tải lên quảng cáo bật lên 300px * 300px',
    floatNotice: 'Vui lòng tải lên quảng cáo nổi 970px * 90px',
    carouselNotice: 'Vui lòng tải lên 1920px * 533px cho phiên bản web của hình ảnh băng chuyền trang chủ',
    carouselNotice2: 'Vui lòng tải lên 428px * 200px cho phiên bản di động của hình ảnh băng chuyền trang chủ',
    applyForAds: 'Áp dụng',
    applyNotice: 'Vui lòng điền thông tin quảng cáo',
    publishTime: 'Thời gian xuất bản',
    adImage: 'Hình ảnh',
    urlPlaceholder: 'Vui lòng nhập URL',
    qrcodePlaceholder: 'Vui lòng tải lên tệp hình ảnh Mã QR',
    backToAdManagement: 'Trở lại',
    adNotice: 'Vui lòng tải lên 199 x 600px cho thanh bên trang chủ.',
    promotionsRegulations: 'Quy chế hoạt động khuyến mại',
    promotionsExpired: 'Hết hạn',
    myRequirements: 'Yêu cầu',
    birthday: 'Ngày sinh',
    birthdayPlaceholder: 'Vui lòng chọnHãy chọn ngày sinh nhật',
    socialMedia: 'Mạng xã hội',
    housingOptions: 'Lựa chọn',
    houseingType: 'Mẫu nhà yêu thích',
    preferenceRegion: 'Khu vực',
    preferenceArea: 'Diện tích',
    amountRange: 'Mức giá',
    specialRequirements: 'Yêu cầu đặt biệt',
    specialRequirementsPlaceholder: 'Vui lòng nhập',
    requirementsDes: 'Điền yêu cầu về nhà ở của bạn, 88go giúp bạn tìm thông tin nhà ở phù hợp !',
    clientManagement: 'Khách hàng',
    newTask: 'Thêm nhiệm vụ mới',
    editTask: 'Chỉnh sửa tác vụ',
    allDayTask: 'Cả ngày',
    inComplete: 'Hoàn tác',
    inProgress: 'Trong tiến trình',
    completed: 'Hoàn thành',
    cancel: 'Hủy bỏ',
    taskNumber: 'Số nhiệm vụ',
    taskName: 'Tên nhiệm vụ',
    clientName: 'Tên',
    taskContent: 'Nội dung',
    report: 'Trả lại',
    checkIn: 'Kiểm tra trong',
    taskList: 'Nhiệm vụ',
    schedule: 'Lịch trình',
    activity: 'Hoạt động',
    salePersonList: 'Nghiệp vụ',
    monthly: 'Mục tiêu hàng tháng',
    weeklyReport: 'Báo cáo tuần',
    toBeReported: 'Sẽ được báo cáo',
    reported: 'Đã báo cáo',
    lastContactTime: 'Thời gian liên lạc lần cuối',
    reportContactInfo: 'Trở lại',
    numberOfBuyersAndSellersToBeReported: 'Báo cáo tổng số lượng chủ sở hữu mua và bán nhà',
    numberOfBuyersAndSellersOfClientToBeReported: 'Báo cáo tổng số lượng khách hàng mua và bán nhà',
    numberOfRentalHouseOwnersToBeReported: 'Số lượng bắt buộc chủ nhà cho thuê phải báo cáo',
    numberOfRentalCustomerToBeReported: 'Báo cáo số lượng khách hàng thuê nhà',
    contactTime: 'Thời gian liên lạc',
    contactTimePlaceholder: 'Hãy nhập thời gian liên lạc',
    contactInfo: 'Thông tin liên hệ',
    reportTime: 'Thời gian báo cáo',
    performanceAmount: 'Mức hiệu suất ',
    signAndServiceFee: '(số tiền hợp đồng/phí dịch vụ)',
    mainSalesperson: 'Chủ yếu ngành kinh doanh',
    signingAmountPlaceholder: 'Vui lòng nhập số tiền hợp đồng',
    monthlyActivity: 'Hoạt động hàng tháng',
    missionHistory: 'Lịch sử nhiệm vụ',
    targetTotal: 'Tổng hiệu suất mục tiêu cho tháng này',
    totalTransaction: 'Tổng kết quả giao dịch trong tháng này',
    targetTotalOfMonth: 'Tổng doanh thu đạt được trong tháng này',
    performance: 'Hiệu suất',
    leaseEntrustment: 'Ủy thác cho thuê (mặt hàng)',
    buyingEntrustment: 'Ủy thác giao dịch (mặt hàng)',
    leaseVisit: 'Tham quan thuê (mặt hàng)',
    transactionVisit: 'Chuyến thăm giao dịch (mặt hàng)',
    goalSetting: 'Cài đặt',
    performanceRegistration: 'Đăng ký',
    addInBatches: 'Tăng',
    addNewGoal: 'Thêm mục tiêu theo đợt',
    performanceGoals: 'Mục tiêu',
    addMonthlyGoal: 'Mục tiêu kinh doanh hàng tháng mới',
    branchList: 'Danh sách nhánh',
    branchInfo: 'Thông tin chi nhánh',
    branchNum: 'Số lượng chi nhánh',
    branchApplication: 'Áp dụng',
    editBranch: 'Sửa chi nhánh',
    addNewBranch: 'Thêm chi nhánh mới',
    addGroupBranch: 'Thêm chi nhánh nhóm mới',
    branchAccount: 'Số tài khoản',
    ownerPosition: 'Việc làm chủ sở hữu',
    branchNotice: 'Nếu đơn đăng ký chi nhánh của bạn không được xem xét trong vòng 7 ngày, đơn đăng ký sẽ tự động được trả lại. Hãy đảm bảo hoàn thành việc xem xét trong thời hạn.',
    notReviewed: 'Chưa được xem xét',
    applyForAccount: 'Số tài khoản',
    applyForAccountPlaceholder: 'Vui lòng nhập tên tài khoản hoặc số điện thoại di động của bạn',
    applyStatus: 'Trạng thái phê duyệt',
    applyStatusPlaceholder: 'Vui lòng chọn trạng thái đánh giá',
    replyStatus: 'Trạng thái trả lời',
    replyStatusPlaceholder: 'Vui lòng chọn trạng thái trả lời',
    applyAccountInfo: 'Thông tin tài khoản',
    applyBranchInfo: 'Thông tin chi nhánh',
    applyForReview: 'Xét duyệt đơn đăng ký',
    approved: 'Tán thành',
    notApproved: 'Không được phê duyệt',
    trade: 'Giao dịch',
    lease: 'Cho thuê',
    houseOwner: 'Gia chủ',
    client: 'Khách hàng',
    transactionItems: 'Các hạng mục giao dịch',
    transactionType: 'Loại giao dịch',
    homeownerType: 'Loại chủ sở hữu nhà',
    entrustment: 'Giao phó',
    homeownerName: 'Tên của chủ sở hữu',
    homeownerAndContract: 'Chủ sở hữu/Hợp đồng',
    homeownerInfo: 'Thông tin chủ nhà',
    itemsSaleInfo: 'Thông tin bán hàng',
    salesInfo: 'Thông tin nhân viên bán hàng',
    clientRequest: 'Nhu cầu của khách hàng',
    entrustedAudit: 'Ủy nhiệm kiểm duyệt',
    contactAudit: 'Liên hệ đánh giá',
    transactionNotReviewed: 'Giao dịch',
    leaseNotReviewed: 'Cho thuê',
    buyerAndSellerOfHomeowners: 'Việc mua bán nhà chưa được chính chủ xem xét',
    buyerAndSellerOfClient: 'Khách hàng mua bán chưa được xét duyệt',
    leasingHomeowners: 'Chủ sở hữu cho thuê chưa hồi đáp',
    leasingClient: 'Khách hàng thuê chưa hồi đáp',
    salespersonReportInfo: 'Thông tin báo cáo doanh nghiệp',
    contactDetails: 'Thông tin liên hệ',
    contactRecord: 'Hồ sơ liên lạc qua điện thoại',
    reviewedDes: 'Vui lòng xác nhận xem phương thức cuộc hẹn có đúng không, sau đó nhấp vào Phê duyệt.',
    contractDes: 'Vui lòng xác nhận số tiền phí dịch vụ có chính xác hay không rồi nhấp vào Phê duyệt.',
    chooseClientType: 'Vui lòng chọn loại khách hàng và danh tính phù hợp',
    clientIdentity: 'Danh tính',
    responsibleForSalesperson: 'Nghiệp vụ',
    demandArea: 'Khu vực nhu cầu',
    demandHouseCategory: 'Yêu cầu về hạng mục nhà ở',
    demandHouseType: 'Yêu cầu về loại nhà ở',
    budget: 'Ngân sách',
    taskDate: 'Ngày tháng',
    taskFormat: 'Cách thức',
    taskLocation: 'Địa điểm',
    sameAsClient: 'Giống như địa chỉ liên hệ của khách hàng',
    taskInfo: 'Thông tin nhiệm vụ',
    clientInfo: 'Thông tin khách hàng',
    contactName: 'Tên người liên lạc',
    contactAddress: 'Địa chỉ',
    reportInfo: 'Thông tin báo cáo',
    contractAmount: 'Số tiền phí dịch vụ',
    staringTime: 'Thời gian bắt đầu',
    endingTime: 'Thời gian kết thúc',
    visitTime: 'Thời gian tham quan',
    reportImgNotice: 'Một bức ảnh có dung lượng giới hạn 50 MB và có thể được tải lên hàng loạt. Hãy đảm bảo rằng bạn có mặt trong ảnh.',
    reportPic: 'Hình ảnh',
    reportContent: 'Nội dung',
    addNewClient: 'Tăng',
    clientNumber: 'Số lượng khách hàng',
    homeownerNumber: 'Số lượng chủ nhà',
    editClient: 'Biên tập',
    visitRecord: 'Hồ sơ thăm viếng',
    visitDate: 'Ngày truy cập',
    taskStatus: 'Trạng thái nhiệm vụ',
    cancelReason: 'Lý do',
    taskDetail: 'Chi tiết sứ mệnh',
    deleteTask: 'Xóa tác vụ',
    willDeleteTask: 'Bạn sẽ xóa nhiệm vụ',
    checkToDelete: 'Bạn có xác nhận xóa không?',
    salesPerson: 'Người bán',
    vipsNumber: 'Chịu trách nhiệm về số lượng khách VIP',
    totalNumberOfPublication: 'Tổng số đơn được ủy thác trong tháng này',
    commissionHistory: 'Quá trình ủy thác',
    vipsTotal: 'Tổng số lượng khách VIP',
    commissionAccount: 'Thành viên được ủy quyền',
    commissionAccountPhone: 'Số điện thoại liên lạc của thành viên được ủy thác',
    rank: 'Xếp hạng',
    totalTask: 'Tổng số nhiệm vụ',
    visitPercent: 'Tỷ lệ truy cập',
    branchAddrPlaceholder: 'Vui lòng nhập địa chỉ chi nhánh',
    salesHistory: 'Lịch sử người bán hàng',
    month: 'Tháng',
    targetMonth: 'Tháng mục tiêu',
    salesGoal: 'Mục tiêu của lực lượng bán hàng',
    transactionPerformance: 'Kết quả giao dịch',
    autoSubscribe: 'Tự động đổi mới',
    upgradeAccount: 'Nâng cấp',
    morePlan: 'Thêm',
    subscribing: 'Đăng ký',
    expired: 'Hết hạn',
    reserve: 'Mở rộng',
    noLicense: 'Trình độ chuyên môn của bạn chưa được chứng nhận. Hãy chắc chắn hoàn thành chứng nhận.',
    goToCertification: 'Đi để',
    goAddNewClient: 'Đi đế',
    groupName: 'Tên công ty',
    waitingForReply: 'Đang chờ trả lời',
    applicationRecords: 'Hồ sơ xin việc chi nhánh',
    addTime: 'Đã thêm thời gian',
    setBranch: 'Tài khoản của bạn được đặt làm chi nhánh nhóm',
    setBranchDes: 'đặt tài khoản của bạn làm chi nhánh. Vui lòng xác nhận xem có thay đổi danh tính này không?',
    becomeBranchMember: 'Bạn đã trở thành thành viên chi nhánh của nhóm!',
    addRequiredAreas: 'Thêm các khu vực bắt buộc mới',
    assistedSalesperson: 'Phối hợp tổ chức kinh doanh',
    chooseSalesperson: 'Vui lòng chọn nhân viên bán hàng',
    categoryOfHousingDemands: 'Loại nhà ở cần thiết',
    categoryOfOfficeDemands: 'Loại văn phòng bắt buộc',
    categoryOfShopDemands: 'Loại cửa hàng bắt buộc',
    typeOfHousingDemands: 'Nhu cầu loại nhà ở',
    typeOfFactoryDemands: 'Loại nhu cầu nhà máy',
    typeOfLandDemands: 'Loại nhu cầu đất đai',
    typeOfOfficeDemands: 'Yêu cầu loại văn phòng',
    typeOfShopDemands: 'Yêu cầu về loại cửa hàng',
    areaDemand: 'Yêu cầu về diện tích',
    layoutDemand: 'Nhu cầu bố trí',
    performanceAudit: 'Kiểm toán',
    contractAudit: 'Kiểm toán hợp đồng',
    secretaryAssignment: 'Thư ký chỉ định',
    unassignedVIP: 'Chưa chỉ định khách VIP',
    vipsInfo: 'Thông tin khách VIP',
    planInfo: 'Thông tin gói dịch vụ',
    assignmentStatus: 'Trạng thái bài tập',
    responsibleSecretary: 'Thư ký phụ trách',
    generalClient: 'Nói chung',
    vip: 'Khách VIP',
    numberOfEntrustPublication: 'Số lượng tin đăng được ủy thác',
    numberOfComplimentaryPublications: 'Số lượng tin đăng ủy thác miễn phí',
    secretaryInfo: 'Thông tin của thư ký',
    changeSecretary: 'Thay đổi thư ký',
    entrustmentRecord: 'Hồ sơ ủy thác',
    numberOfPublication: 'Số lượng đơn hàng',
    branchDetail: 'Chi tiết chi nhánh',
    checkInTime: 'Giờ nhận phòng',
    checkInLocation: 'Địa điểm nhận phòng',
    taskCheckIn: 'Kiểm tra nhiệm vụ',
    notCheckIn: 'Không nhận phòng',
    taskCheckInDes: 'Bạn đã xác nhận việc bạn đến địa điểm này chưa?',
    hasClientInfo: 'Lấy thông tin khách hàng',
    hasClientInfoDes: 'Bạn có thu được thông tin khách hàng trong quá trình thực hiện nhiệm vụ không?',
    realPrice: 'Đăng ký theo giá thực',
    realPriceDes: 'Thông tin này chỉ được sử dụng để thống kê trang web Sau khi xem xét giá đăng ký thực tế, 10 điểm sẽ được thưởng cho các thành viên trong vòng 3 - 7 ngày.',
    dealPrice: 'Số tiền giao dịch',
    addNewSecretary: 'Thêm thư ký mới',
    secretaryManagement: 'Quản lý thư ký',
    secretaryAccount: 'Tài khoản thư ký',
    freeDedicatedPublication: 'Đội ngũ chuyên môn đăng tin miễn phí',
    expiredOn: 'Hết hạn vào',
    clickFirst: 'Vui lòng nhấp vào đây trước',
    downloadTemplate: 'Tải xuống ví dụ',
    instructionsForUse: 'Hướng dẫn sử dụng',
    importDescription1: 'Vui lòng tải xuống tệp mẫu nhập trước.',
    importDescription2: 'Điền thông tin đối tượng tương ứng theo nội dung bảng.',
    importDescription3: 'Quay lại Khu vực Thành viên 88go > Quản lý danh sách > Nhà chưa niêm yết, nhấp vào Tải lên tệp đối tượng và tải lên biểu mẫu phòng bất động sản nhập hàng loạt.',
    importDescription4: 'Nếu tệp tải lên chính xác, dữ liệu đối tượng sẽ tự động được nhập vào danh sách không liệt kê.',
    uploadFile: 'Tải tập tin lên',
    field: 'Cánh đồng',
    friends: 'Được bạn bè đề xuất',
    inviteFriendsLink: 'Chia sẽ liên kết để mời bạn bè tham gia',
    totalRewardsThisMonth: 'Tổng tiền thưởng tháng này',
    personalPublishingRewards: 'Tiền thưởng cho cá nhân đăng tin',
    firstLevelRewards: 'Tiền thưởng cấp bậc 1',
    secondLevelRewards: 'Tiền thưởng cấp bậc 2',
    thirdLevelRewards: 'Tiền thưởng cấp bậc 3',
    bonusStatistics: 'Tiền thưởng được ghi nhận trong tháng',
    numberOfPersonalListings: 'Số lượng tin đăng của cá nhân',
    numberOfFriendsPublishing: 'Số lượng tin đăng của bạn bè',
    numberOfPeoplePublishedByFriends: 'Số lượng bạn bè đăng tin',
    numberOfPublishingOnTheSecondLevel: 'Tổng số tin đăng thuộc bậc 2',
    numberOfPeoplePublishingOnTheSecondLevel: 'ố lượng người đăng tin thuộc bậc 2',
    numberOfPublishingOnTheThirdLevel: 'Tổng số tin đăng thuộc bậc 3',
    numberOfPeoplePublishingOnTheThirdLevel: 'Số lượng người đăng tin thuộc bậc 3',
    memberName: 'Tên thành viên',
    memberNamePlaceholder: 'Vui lòng cung cấp tên thành viên',
    friendsInfo: 'Thông tin của bạn bè',
    numberOfPeopleInTheDownLine: 'Tổng số thành viên',
    numberOfPeoplePublishedInTheDownLine: 'Số thành viên hiện không đăng tin',
    totalNumberOfListingsBySubordinates: 'Tổng số bài đăng của các thành viên',
    friendsRule: 'Nguyên tắc mời bạn bè',
    friendDetails: 'Thông tin chi tiết của bạn bè',
    statisticsForThisMonth: 'Tổng kết trong tháng',
    downLineList: 'Danh sách thành viên',
    detailOfDownLine: 'hông tin chi tiết về bạn bè của của bạn bè',
    back: 'Trở lại',
    contactRecords: 'Bản ghi liên hệ',
    clickedDate: 'Ngày được nhấp',
    contactWay: 'Cách liên lạc',
    yourProperty: 'Đối tượng của bạn',
  },
  forumPage: {
    enTitle: 'Forum',
    forumHome: 'Trang đầu',
    myArticles: 'Bài viết của tôi',
    articleType: 'Loại bài viết',
    searchArticlePlaceholder: 'Tìm kiếm',
    numberOfArticles: 'Số bài viết',
    numberOfReplies: 'Trả lời',
    publishArticle: 'Đăng bài viết',
    all: 'Tất cả',
    general: 'Chung',
    featured: 'Nổi bật',
    popular: 'Phổ biến',
    type: 'Loại bài viết',
    time: 'Thời gian',
    repliesViews: 'Trả lời/Xem',
    views: 'Xem',
    login: 'Đăng nhập',
    canPublish: 'Bạn có thể đăng bài viết và để lại bình luận',
    registerAt: 'Đăng ký tại',
    edit: 'biên tập',
    delete: 'xóa bỏ',
    reply: 'hồi đáp',
    replyTitle: 'Hồi đáp',
    replyDescription: 'Nhập nội dung trả lời của bạn và đảm bảo tuân thủ các quy định của diễn đàn',
    send: 'Gửi',
    rule: 'Luật lệ',
    ruleDes: 'Vui lòng đọc kỹ nội quy diễn đàn trước khi gửi bài, tất cả thành viên phải tuân thủ các nguyên tắc trên, nếu không đồng ý vui lòng không bình luận tại đây.',
    // eslint-disable-next-line max-len
    ruleItem1: 'Thành viên của Mạng lưới giao dịch 88go được quyền bình luận về tất cả các bài viết, các chủ đề và nội dung trên diễn đàn này không thể hiện quan điểm của 88go. Vui lòng tuân thủ các quy định của pháp luật khi đăng bình luận và tuân thủ mục đích của diễn đàn này, nghiêm cấm việc chia tách.',
    // eslint-disable-next-line max-len
    ruleItem2: 'Trước khi xuất bản một bài viết, các thành viên phải xác nhận rằng nội dung (hình ảnh) của bài viết mình đăng không vi phạm bản quyền, nhãn hiệu, bằng sáng chế và các quyền khác của người khác. Nếu có bất kỳ trách nhiệm pháp lý nào phát sinh từ các bài viết do thành viên xuất bản thì các thành viên đó sẽ tự chịu trách nhiệm.',
    // eslint-disable-next-line max-len
    ruleItem3: 'Thành viên đồng ý không đăng bất cứ điều gì trong diễn đàn có tính chất lừa đảo, phỉ báng, sai sự thật, lạm dụng, thô tục, có hại, quấy rối, tục tĩu, tục tĩu, có khuynh hướng tình dục, đe dọa xâm phạm quyền riêng tư của người khác hoặc vi phạm bất kỳ Thông tin nào về quy định pháp luật. Nếu phát hiện bị báo cáo, 88go có quyền tự xóa, nếu tình tiết nghiêm trọng sẽ bị thu hồi tư cách thành viên.',
    ruleItem4: 'Vui lòng không để lại thông tin riêng tư cá nhân như số CMND, địa chỉ để tránh bị sử dụng trái phép, 88go sẽ không chịu trách nhiệm quản lý trường hợp này.',
    articleTitle: 'Tiêu đề bài viết',
    articleTitlePlaceholder: 'Vui lòng nhập tiêu đề bài viết',
    articleContent: 'Nội dung bài báo',
    pin: 'Ghim',
    deletedMsg: 'Tin nhắn đã bị xóa',
    replyTo: 'Trả lời',
    editArticle: 'Biên tập',
    joinComments: 'Bạn có thể tham gia thảo luận sau khi đăng nhập và đăng ký',
  },
  dialog: {
    noLoginYet: 'Chưa đăng nhập',
    noLogin: 'Để biết thêm thông tin，Vui lòng vào đăng nhập hoặc đăng ký',
    goLogin: 'Đi đến đăng nhập',
    cancel: 'Hủy',
    guestIdentity: 'Hiện là người mua/người thuê',
    guestDescription1: 'Bạn hiện là người mua/người thuê và không thể quảng cáo cho thuê/bán',
    guestDescription2: 'Bạn có muốn chuyển đổi',
    switchIdentity: 'Chuyển đổi',
    confirmUpload: 'Xác nhận tải lên',
    noPoints: 'Không đủ điểm',
    noPointsDescription: 'Bạn hiện không đủ điểm, vui lòng nạp trước khi mua',
    remainPoints: 'Điểm hiện có:',
    pointsRequired: 'Điểm cần trả',
    needToStore: 'Cần nạp thêm điểm',
    store: 'Nạp',
    storedPoints: 'Nạp điểm',
    paymentMethods: 'Phương thức thanh toán',
    transfer: 'Thanh toán chuyển khoản',
    submit: 'Nộp',
    payment: 'Sự chi trả',
    addSubAccount: 'Thêm tiểu tài khoản',
    editSubAccount: 'Sửa tài khoản phụ',
    memberStatus: 'Tình trạng',
    deleteMsg: 'Xóa tin nhắn',
    deleteMsgDes: 'Bạn có chắc chắn muốn xóa tin nhắn?',
    confirmDeletion: 'Xác nhận xóa',
    reporting: 'Báo cáo',
    reportContent: 'Báo cáo nội dung',
    reportNow: 'Báo cáo',
    adApplicationDetails: 'Chi tiết yêu cầu quảng cáo',
    adInfo: 'Thông tin quảng cáo',
    applicationTime: 'Thời gian ',
    applicantName: 'Tên người yêu cầu',
    reviewResults: 'Kết quả kiểm duyệt',
    reason: 'Lý do',
    expired: 'Hết hạn',
    comfirmClose: 'Xác nhận',
    closeDes: 'Bạn có chắc chắn đóng đối tượng này không?',
    // eslint-disable-next-line max-len
    closeNotice: '*Sau khi mục đang được xuất bản bị đóng, thời gian xuất bản vẫn được tính như bình thường. Nếu bị đóng rồi mở lại trong thời hạn hiệu lực thì không cần phải trả tiền xuất bản nữa.',
    dealHouse: 'Bạn có xác nhận giao dịch của mặt hàng này không?',
    dealNotice: '*Nếu bạn muốn liệt kê lại các mặt hàng được liệt kê sau khi chúng được bán, bạn sẽ phải tính lại phí bổ sung lại.',
    deleteArticle: 'Xóa bài báo',
    deleteArticleNotice: 'Bạn có chắc chắn xóa bài viết? Hành động này sẽ không được hoàn tác',
    deleteComment: 'Xóa trả lời',
    deleteCommentNotice: 'Bạn có chắc chắn xóa câu trả lời không? Hành động này sẽ không được hoàn tác',
    promotionDetail: 'Chi tiết ngoại tuyến',
    downlineName: 'Tên tuyến dưới của',
    houseClosed: 'Sự vật đã đóng, bạn sẽ quay lại trang trước sau khi xác nhận.',
    storeClosed: 'Cửa hàng đã đóng, bạn sẽ được đưa trở lại trang trước sau khi xác nhận.',
    orderTime: 'Giờ đặt hàng',
    orderAmount: 'Số tiền đặt hàng',
    idContent: 'Hướng dẫn tải CMND lên',
    idCardCover: 'Vui lòng che thông tin mã vạch quan trọng (QR Code) khi tải CMND của bạn lên',
    idCardDescription: 'Và đảm bảo rằng các thông tin CMND khác và nội dung khác được hiển thị rõ ràng. Ảnh phải ở định dạng jpg, gif hoặc png và nhỏ hơn 50M.',
    thirdPartyCondition: 'Số tiền bên thứ ba cần thanh toán: Hạn mức tối thiểu: 10.000 VNĐ Hạn mức tối đa: 200.000.000 VNĐ',
    applicantPosition: 'Vị trí của ứng viên',
    belongToGroup: 'Nhóm',
    branchAddr: 'Địa chỉ chi nhánh',
    addBranch: 'Vui lòng xác nhận xem nội dung đơn đăng ký chi nhánh có chính xác hay không, sau đó nhấp vào Phê duyệt.',
    accountName: 'Tên tài khoản',
    joinTheGroup: 'Tham gia vào nhóm',
    refuseToJoinTheGroup: 'Từ chối tham gia nhóm',
    checkDes: 'Bạn có muốn xác nhận thao tác này không?',
    deleteSecretary: 'Xóa thư ký',
    deleteSecretaryNotice: 'Bạn có chắc chắn xóa thư ký? Sau khi xác nhận sẽ không được hoàn tác',
    importDataError: 'Nhập dữ liệu lỗi',
    // eslint-disable-next-line max-len
    importDataErrorNotice: 'Có lỗi trong dữ liệu đã nhập. Vui lòng xác nhận nội dung tệp và mã lỗi bên dưới, sửa và tải lại. Nếu bạn có bất kỳ câu hỏi nào, vui lòng liên hệ với dịch vụ khách hàng.',
    sheetName: 'Tên bảng tính',
    systemError: 'Lỗi hệ thống',
    systemErrorNotice: 'Lỗi hệ thống, vui lòng thử lại sau. Nếu bạn có bất kỳ câu hỏi nào, vui lòng liên hệ với dịch vụ khách hàng.',
    checkContactAudit: 'Bạn có chắc chắn muốn báo cáo lại qua địa chỉ liên hệ này không?？',
  },
  clear: 'Xóa',
  buyer: 'Người mua/Người thuê',
  owner: 'Chủ sở hữu/Người đại diện',
  estateAgent: 'Người quản lý/Môi giới',
  estateAgency: 'Công ty môi giới',
  save: 'Lưu',
  saveAndChange: 'Lưu thay đổi',
  enable: 'cho phép',
  disable: 'Vô hiệu hóa',
  selectLanguage: 'Vui lòng chọn ngôn ngữ',
  language: 'Ngôn ngữ',
  transfer: 'Thanh toán chuyển khoản',
  bankName: 'Tên ngân hàng',
  bankCode: 'Mã ngân hàng',
  bankAccount: 'Tài khoản ngân hàng',
  storedImmediately: 'Nạp tiền',
  rechargedAmount: 'Số tiền',
  remittanceVerification: 'Xác minh chuyển tiền',
  submit: 'Xác nhận gửi',
  group: 'Nhóm',
  branch: 'Chi nhánh',
  code: {
    1: 'Thành công',
    1001: 'Mã hết hạn',
    1002: 'Mã không hợp lệ',
    1003: 'Mã không được phép',
    2001: 'Yêu cầu không hợp lệ',
    2002: 'Lỗi hệ thống',
    2003: 'Lỗi cơ sở dữ liệu',
    2004: 'Không có quyền truy cập',
    2005: 'Dữ liệu không tồn tại',
    3001: 'Tài khoản không hợp lệ',
    3002: 'Mật khẩu không hợp lệ',
    3003: 'Tài khoản đã tồn tại',
    3004: 'Số điện thoại đã tồn tại',
    3005: 'Gửi mã xác minh nhiều lần',
    3006: 'Mã xác minh không hợp lệ',
    3007: 'Mã xác minh đã hết hạn',
    3008: 'Đã xác minh tài khoản',
    3009: 'Mật khẩu đã tồn tại',
    3010: 'Mật khẩu không tồn tại',
    3011: 'Đánh giá đã tồn tại',
    3012: 'Đánh giá đã đóng',
    3013: 'Tài khoản đã ngừng hoạt động',
    3014: 'Không thể chuyển đổi vai trò hiện tại',
    3015: 'Nhà ở không tồn tại',
    3016: 'Cửa hàng đã đóng',
    3017: 'Cửa hàng không tồn tại',
    3018: 'Tài khoản không tồn tại',
    3019: 'Trong quá trình kiểm duyệt',
    3020: 'Đã phê duyệt',
    3021: 'Hỏi đáp không tồn tại',
    3022: 'Lặp lại trong vòng một giờ',
    3023: 'Lặp lại tin nhắn trong vòng một phút',
    3024: 'Số điện thoại không hợp lệ',
    3025: 'Trạng thái quảng cáo không hợp lệ',
    3026: 'Phương thức thanh toán không hợp lệ',
    3027: 'Trạng thái đơn hàng không hợp lệ',
    3028: 'Url đã hết hạn',
    3029: 'Url không hợp lệ',
    3030: 'Chưa đến thời gian hoạt động',
    3031: 'Đã xảy ra lỗi khi trả lời tin nhắn',
    3032: 'Nhiệm vụ đã hết hạn',
    3033: 'Tài khoản phụ không thuộc về người dùng',
    3034: 'Đã đạt đến số lượng nhánh nhóm tối đa',
    3035: 'Chi nhánh đã có trong nhóm hoặc trạng thái chi nhánh không chính xác',
    3036: 'Hồ sơ kiểm toán chi nhánh không tồn tại hoặc đã được kiểm toán',
    3037: 'Đăng ký chưa hoàn tất',
    3038: 'Khách hàng vẫn chưa chỉ định kiểm toán',
    3039: 'Mã mời không hợp lệ',
    4001: 'Không phải chủ sở hữu được liệt kê hoặc tình trạng tài sản không đúng',
    4002: 'Loại nhà và gói dịch vụ không khớp',
    4003: 'Không có gói',
    4004: 'Không có tin đăng còn lại',
    4005: 'Không có thời gian tin đăng còn lại',
    4006: 'Điểm tài khoản không đủ',
    4007: 'Nhà ở đã áp dụng gói nạp tiền',
    4008: 'Nhà ở đã áp dụng gói tăng cường hiền thị',
    4009: 'Sai số tài khoản',
    4010: 'Loại gói gia hạn không khớp',
    4011: 'Không có bản ghi cập nhật thủ công',
    4012: 'Cập nhật thủ công không đầy đủ',
    4013: 'Cập nhật thủ công mất chưa đầy 1 giờ',
    4014: 'Vai trò tài khoản là đại lý hoặc công ty đại lý. Bạn cần điền thông tin trước khi xuất bản. Bạn chỉ có thể xuất bản sau khi vượt qua quá trình xem xét.',
    4015: 'Lượng phơi sáng không được nhỏ hơn 19',
    4016: 'Nó đã bị đóng bởi chương trình phụ trợ và không thể đăng lại.',
    4017: 'Chỉ có thể đặt một kế hoạch nhóm',
    4018: 'Gói nhóm chỉ có thể được nâng cấp',
    4020: 'Không phải tài khoản công ty',
    4021: 'Không phải thư ký',
    4022: 'Chưa mua đội ngũ chuyên đăng tin',
    4023: 'Tài khoản phụ không phải là thư ký',
    4024: 'Trong dịch vụ vẫn còn VIP và không thể xóa được.',
    4025: 'Số lần sửa đổi địa chỉ của đối tượng trong danh sách hoặc vượt quá thời gian sửa đổi',
    5001: 'xlsx lỗi',
    5002: 'Trường đang trống',
    5003: 'Độ dài trường sai',
    5004: 'Số trường sai',
    5005: 'Lỗi tập tin',
  },
  abuse_house: {
    status: {
      0: 'Chưa xử lý',
      1: 'Đang xử lý',
      2: 'Đã xử lý',
    },
  },
  account: {
    gender: {
      1: 'Nam',
      2: 'Nữ',
      3: 'Không xác định',
    },
    status: {
      0: 'Vô hiệu hóa',
      1: 'Kích hoạt',
    },
    verify_stat: {
      0: 'Chưa xác minh',
      1: 'Đã xác minh',
    },
  },
  account_point_history: {
    type: {
      0: 'Tất cả',
      1: 'Mua điểm',
      2: 'Gói đăng tin',
      3: 'Gói thành viên',
      4: 'Tỉ lệ hiển thị',
      5: 'Dịch vụ nạp tiền',
      6: 'Gói quảng cáo',
      7: 'Gói đại lí',
      8: 'Gửi tiền thủ công',
      9: 'Sự kiện quảng bá',
      10: 'Nhóm',
      11: 'Điểm nâng cấp chênh lệch',
      12: 'Tiền thưởng đăng ký giá thực tế',
      13: 'Đăng bởi đội ngũ chuyên môn',
    },
    daily: 'Ngày/Hàng ngày:',
  },
  plan_type: {
    type: {
      0: 'Tất cả',
      1: 'Cơ bản',
      2: 'Cho thuê',
      3: 'Mua bán',
      4: 'Quảng cáo',
    },
  },
  account_qi: {
    cert_type: {
      0: '',
      1: 'Nhân viên bán hàng',
      2: 'Người quản lý',
    },
    ownership: {
      1: 'Tập đoàn',
      2: 'Chi nhánh',
      3: 'Khác',
    },
    company_position: {
      1: 'Chủ tịch',
      2: 'Tổng giám đốc/CEO',
      3: 'Giám đốc',
      4: 'Quản lý',
      5: 'Trung gian/Môi giới viên',
      6: 'Trợ lý',
      7: 'Khác',
    },
    e_identity: {
      1: 'Thư ký',
      2: 'Khác',
    },
    store_type: {
      1: 'Trực tiếp',
      2: 'Nhượng quyền',
    },
    status: {
      0: 'Chưa kiểm duyệt',
      1: 'Đang kiểm duyệt',
      2: 'Kiểm duyệt thành công',
      3: 'Kiểm duyệt thất bại',
    },
  },
  account_role: {
    type: {
      1: 'Người mua/Người thuê',
      2: 'Chủ sở hữu/người đại diện',
      3: 'Người quản lý/Môi giới',
      4: 'Công ty môi giới',
      5: 'Tiểu tài khoản',
    },
  },
  account_sub: {
    gender: {
      1: 'Nam',
      2: 'Nữ',
      3: 'Không xác định',
    },
    status: {
      0: 'Vô hiệu hóa',
      1: 'Kích hoạt',
    },
  },
  ad_apply: {
    block: {
      1: 'Thanh bên trang chủ',
    },
    type: {
      1: 'Yêu cầu trước nền',
      2: 'Yêu cầu sau nền',
    },
    status: {
      0: 'Chưa kiểm duyệt',
      1: 'Đang kiểm duyệt',
      2: 'Phê Duyệt',
      3: 'Từ chối',
      4: 'Đóng tin',
    },
  },
  ad_apply_bank: {
    status: {
      0: 'Chưa thanh toán',
      1: 'Đã thanh toán',
    },
  },
  forum: {
    type: {
      1: 'Thuê nhà',
      2: 'Mua bán',
      3: 'Dự án mới',
    },
    tag: {
      0: 'Chung',
      1: 'Nổi bật',
    },
    status: {
      0: 'Tin đã đăng',
      1: 'tự đóng',
      2: 'Đóng nền',
    },
    close_reason: {
      1: 'Không liên quan nhà ở',
      2: 'Trùng lặp',
      3: 'Khác',
    },
  },
  forum_comment: {
    status: {
      0: 'Bình thường',
      1: 'Tự xóa',
      2: 'Xóa nền',
    },
    del_reason: {
      1: 'Không liên quan nhà ở',
      2: 'Trùng lặp',
      3: 'Khác',
    },
  },
  house: {
    type: {
      1: 'Thuê nhà',
      2: 'Bán nhà',
    },
    registration: {
      0: 'Hợ đồng chuyển nhượng',
      1: 'Đã có sổ đỏ',
    },
    saleRegistration: {
      0: 'Hợ đồng chuyển nhượng',
      1: 'Đã có sổ đỏ',
    },
    status: {
      0: 'Tin chưa đăng',
      1: 'Tin quá hạn',
      2: 'Tự đóng',
      3: 'Khách hàng đã đóng',
      4: 'Tin đã đăng',
      5: 'Đã giao dịch',
    },
  },
  house_base_rule: {
    type: {
      1: 'Bếp lửa',
      2: 'Thuế',
      3: 'Nhập hộ',
      4: 'Bàn thờ',
    },
  },
  house_equipment: {
    type: {
      1: 'Máy giặt',
      2: 'Tủ lạnh',
      3: 'Mạng',
      4: 'TV',
      5: 'Truyền hình thu phí',
      6: 'Máy nước nóng',
      7: 'Máy lạnh',
      8: 'Khí gas tự nhiên',
      9: 'Lò vi sóng',
      10: 'Giường',
      11: 'Tủ quần áo',
      12: 'Ghế sô pha',
      13: 'Bàn ghế',
      14: 'Ban công',
      15: 'Chỗ đậu xe',
      16: 'Thang máy',
    },
  },
  house_files: {
    type: {
      1: 'Hình ảnh',
      2: 'Sơ đồ',
      3: 'Video',
      4: 'VR 360°',
    },
  },
  house_fire_security: {
    type: {
      1: 'Bình cứu hỏa',
      2: 'Thang cuốn',
      3: 'Thiết bị chiếu sáng',
      4: 'Hệ thống giám sát',
    },
  },
  house_identity_rule: {
    type: {
      1: 'Nhân viên văn phòng',
      2: 'Gia đình',
      3: 'Học sinh',
      4: 'Người trong nước',
      5: 'Người nước ngoài',
    },
  },
  house_info: {
    house_category: {
      0: 'Không giới hạn',
      1: 'Nhà nguyên căn',
      2: 'Phòng chung',
      3: 'Nhà ở',
      4: 'Căn hộ',
      5: 'Nhà ở',
      6: 'Nhà đấu giá',
      7: 'Khác',
    },
    house_type: {
      0: 'Không giới hạn',
      1: 'Chung cư',
      2: 'Cao ốc',
      3: 'Nhà riêng',
      4: 'Villa',
    },
    front: {
      0: 'Không giới hạn',
      1: 'Đông',
      2: 'Nam',
      3: 'Tây',
      4: 'Bắc',
      5: 'Đông nam',
      6: 'Tây nam',
      7: 'Đông bắc',
      8: 'Tây bắc',
    },
    decoration: {
      0: 'Không xác định',
      1: 'Chưa được trang trí',
      2: 'Trang trí đơn giản',
      3: 'Trang trí tầm trung',
      4: 'Trang trí cao cấp',
    },
    parking_type: {
      0: 'Không có chỗ đậu xe',
      1: 'Chỗ đậu xe kiểu thang máy',
      2: 'Chỗ đậu xe phẳng',
    },
    mng_fee_type: {
      0: 'KHÔNG',
      1: 'Tháng',
      2: '2 tháng',
      3: 'Quý',
    },
    legally_usage: {
      0: 'Không xác định',
      1: 'Nhà ở',
      2: 'Kinh doanh',
      3: 'Của hàng',
      4: 'Doanh nghiệp',
      5: 'Khác',
    },
  },
  house_pet_rule: {
    type: {
      1: 'Mèo',
      2: 'Cún',
      3: 'Khác',
    },
  },
  house_public_facility: {
    type: {
      1: 'Sảnh lễ tân',
      2: 'Vườn trên sân thượng',
      3: 'Phòng hội nghị',
      4: 'Phòng đọc sách',
      5: 'Phòng tập thể dục',
      6: 'Khu giải trí',
      7: 'Lớp học Yoga',
      8: 'Khu hộp thư',
      9: 'Khu phơi chăn màn',
      10: 'Khu BBQ',
    },
  },
  house_rent_pay_includes: {
    type: {
      1: 'Tiền điện',
      2: 'Tiền nước',
      3: 'Truyền hình',
      4: 'Internet',
      5: 'Phí Gas',
      6: 'Phí quản lý',
    },
  },
  message: {
    type: {
      0: 'Tất cả',
      1: 'Hệ thống',
      2: 'Thông báo liên hệ',
    },
    status: {
      0: 'Chưa phát hành',
      1: 'Đã phát hành',
    },
  },
  new_house: {
    case_type: {
      1: 'Nhà sắp ra mắt',
      2: 'Nhà dự án đang xây dựng',
      3: 'nhà mới',
    },
    house_type: {
      1: 'Chung cư',
      2: 'Căn hộ',
      3: 'nhà nguyên căn',
      4: 'Biệt thự',
    },
    sale_stat: {
      0: 'Chưa bắt đầu',
      1: 'Đang bán',
      2: 'Bán xong',
    },
    status: {
      0: 'Chưa đăng tin',
      1: 'Đang đăng tin',
      2: 'Dịch vụ đã đóng',
    },
    mng_fee_type: {
      1: 'Tháng',
      2: '2 tháng',
      3: 'Quý',
    },
    front: {
      0: 'Không giới hạn',
      1: 'Đông',
      2: 'Nam',
      3: 'Tây',
      4: 'Bắc',
      5: 'Đông nam',
      6: 'Tây nam',
      7: 'Đông bắc',
      8: 'Tây bắc',
    },
    deal_stat: {
      0: 'Thiết bị tiêu chuẩn',
      1: 'Trang trí cơ bản',
      2: 'Nhà thô',
    },
  },
  new_house_files: {
    type: {
      1: 'Bìa',
      2: 'Video',
      3: 'VR 360°',
      4: 'Mặt phẳng',
      5: 'Giao thông',
      6: 'Biểu tượng',
      7: 'Thực tế',
      8: 'Xung quanh',
      9: 'Thật',
    },
  },
  new_house_public_facility: {
    type: {
      1: 'Sảnh lễ tân',
      2: 'Vườn trên sân thượng',
      3: 'Phòng hội nghị',
      4: 'Phòng đọc sách',
      5: 'Phòng tập thể dục',
      6: 'Khu giải trí',
      7: 'Lớp học Yoga',
      8: 'Khu hộp thư',
      9: 'Khu phơi chăn màn',
      10: 'Khu BBQ',
    },
  },
  factory: {
    type: {
      1: 'Nhà xưởng',
      2: 'Văn phòng',
      3: 'Trang trại',
      4: 'Nhà kho',
      5: 'Văn phòng',
    },
    deposit: {
      1: 'Một tháng',
      2: 'Hai tháng',
      3: 'Ba tháng',
      4: 'Thương lượng',
    },
    minLease: {
      1: 'Một năm',
      2: 'Hai năm',
      3: 'Ba năm',
      4: 'Bốn năm',
      5: 'Năm năm',
      6: 'Sáu năm',
      7: 'Bảy năm',
      8: 'Tám năm',
      9: 'Chín năm',
      10: 'Mười năm',
      11: 'Thương lượng',
    },
    partitions: {
      1: 'Đợi xác nhận',
      2: 'Trong khu công nghiệp',
      3: 'Ngoài khu công nghiệp',
    },
    structure: {
      1: 'RC',
      2: 'Thép',
      3: 'Gạch và đá',
      4: 'Pha trộn',
      5: 'Gạch và gỗ',
      6: 'Bằng gỗ',
    },
    unloading: {
      1: 'Mặt đơn',
      2: 'Hai mặt',
      3: 'Ba mặt',
      4: 'Mọi phía',
    },
    registration: {
      1: 'Có',
      2: 'Không',
      3: 'Đợi xác nhận',
    },
    industry: {
      0: 'Không hạn chế',
      1: 'Bảo hiểm tài chính',
      2: 'Dịch vụ tư vấn',
      3: 'Công nghệ thông tin',
      4: 'Văn hóa truyền thống',
      5: 'Giáo dục và đào tạo',
      6: 'Nhà sản xuất ô tô',
      7: 'Điện tử kỹ thuật số',
      8: 'Thương mại và bán lẻ',
      9: 'Phục vụ',
      10: 'Ngành hoá chất y tế',
      11: 'Công nghệ cao',
      12: 'Bộ thông tin và truyền thông',
      13: 'Hàng tiêu dùng nhanh',
      14: 'Bất động sản',
      15: 'Trò chơi giải trí',
      16: 'Khác',
    },
    disabledIndustry: {
      0: 'Chọn tất cả',
      1: 'Gây ô nhiễm',
      2: 'Hóa chất',
      3: 'Tiếng ồn',
      4: 'Khác',
    },
    basic_equipment: {
      0: 'Chọn tất cả',
      1: 'Điện ba pha 380V',
      2: 'Chỗ để xe',
      3: 'Khí gas tự nhiên',
      4: 'Mạng',
      5: 'Máy điều hòa',
      6: 'Ký túc xá',
      7: 'Nhà ăn',
    },
    handling_equipment: {
      0: 'Chọn tất cả',
      1: 'Thang máy chở hàng',
      2: 'Máy tạo dự phòng',
      3: 'Thang máy',
      4: 'Máy trục',
      5: 'Xe xếp',
      6: 'Xe nâng điện',
      7: 'Khay',
      8: 'Những cái kệ',
      9: 'Dòng phân loại',
    },
  },
  land: {
    type: {
      1: 'Đất ở ',
      2: 'Đất thương mại',
      3: 'Đất công nghiệ ',
      4: 'Đất xây dựng',
      5: 'Đất nông nghiệp',
      6: 'Đất lâm nghiệp',
      7: 'Khác',
    },
    purpose: {
      0: 'Không hạn chế',
      1: 'Nông nghiệp',
      2: 'Dân cư',
      3: 'Nhà xưởng chế biến',
      4: 'Doanh nghiệp vừa và nhỏ',
      5: 'Ngành công nghệ cao',
      6: 'Nhà xưởng',
      7: 'Nhà kho',
      8: 'Quảng cáo',
      9: 'Cửa hàng',
    },
    infrastructure: {
      0: 'Chọn tất cả',
      1: 'điện',
      2: 'Nước',
      3: 'đường',
      4: 'Mặt đất bằng phẳng',
      5: 'Đường dây mạng',
    },
  },
  office: {
    category: {
      1: 'Văn phòng',
      2: 'Văn phòng dân cư',
    },
    type: {
      1: 'Chung cư',
      2: 'Biệt thự',
      3: 'nhà nguyên căn',
      4: 'Cửa hàng',
      5: 'Căn hộ',
    },
    tax: {
      0: 'Không bao gồm thuế',
      1: 'Bao gồm thuế',
    },
    electricity: {
      0: 'Chưa bao gồm tiền điện',
      1: 'Bao gồm tiền điện',
    },
    registration: {
      0: 'Đợi xác nhận',
      1: 'Có',
      2: 'Không',
    },
    equipment: {
      1: 'Mạng',
      2: 'Đồ nội thất',
      3: 'Máy điều hòa',
      4: 'Phòng vệ sinh riêng',
      5: 'Nhân viên an ninh',
      6: 'Căng tin nhân viên',
      7: 'Họp thư tập trung',
      8: 'Lễ tân đón khách',
      9: 'Dịch vụ vệ sinh',
      10: 'Phòng đàm phán',
      11: 'Phòng họp',
      12: 'Thư viện',
      13: 'Khu giải trí',
      14: 'Phòng hội nghị',
      15: 'Phòng pantry văn phòng',
      16: 'Máy bán hàng tự động',
      17: 'Tủ lạnh',
      18: 'Lò vi sóng',
      19: 'Máy lọc nước',
      20: 'Máy lọc không khí',
      21: 'WIFI',
      22: 'Thiết bị tập thể dục',
      23: 'Máy photocoppy',
    },
  },
  shop: {
    category: {
      1: 'Cửa hàng phố thương mại',
      2: 'Tầng trệt khu thương mại',
      3: 'Văn phòng công ty',
      4: 'Trung tâm mua sắm/ cửa hàng bách hóa',
      5: 'Cửa hàng ven đường/ mặt tiền',
      6: 'Quầy hàng',
      7: 'Cửa hàng giao thông vận tải',
      8: 'Khác',
    },
    type: {
      1: 'Chung cư',
      2: 'Biệt thự',
      3: 'nhà nguyên căn',
      4: 'Cửa hàng',
      5: 'Căn hộ',
    },
    business_status: {
      1: 'Đang trống',
      2: 'Đang hoạt động',
      3: 'Cho thuê',
    },
    disabled: {
      1: 'Ngành phục vụ bếp ăn công nghiệp',
      2: 'Các ngành đặc biệt',
      3: 'Máy gấp thú',
      4: 'Cửa hàng thú cưng',
      5: 'Nghề rửa xe',
      6: 'Công viên giải trí',
      7: 'Nhóm tôn giáo',
    },
    customer_flow: {
      1: 'Nhân viên văn phòng',
      2: 'Cư dân lân cận',
      3: 'Khách du lịch',
    },
    equipment: {
      1: 'Nước máy',
      2: 'Xả nước thải',
      3: 'Điện ba pha 380V',
      4: 'Ống xả khói',
      5: 'Ống nước thải',
      6: 'Khí gas tự nhiên',
      7: 'Máy điều hòa',
      8: 'Mạng',
      9: 'Phòng vệ sinh riêng',
      10: 'Lối đi riêng',
      11: 'Máy nước nóng',
    },
  },
  ord: {
    status: {
      0: 'Chưa thanh toán',
      1: 'Đã chuyển tiền',
      2: 'Đã thanh toán',
      3: 'Chưa thanh toán',
      4: 'Thanh toán thất bại',
    },
    pay_type: {
      0: 'Nạp thủ công',
      1: 'Chuyển tiền',
      2: 'Ví điện tử',
      3: 'Quyết toán hoạt động',
      4: 'Điểm nâng cấp chênh lệch',
    },
  },
  ord_expose: {
    type: {
      1: 'Nền tảng web',
      2: 'Nền tảng di động',
    },
  },
  plan_ad: {
    block: {
      1: 'Thanh bên trang chủ',
      2: 'Cửa sổ bật lên',
      3: 'Trôi nổi',
      4: 'Quảng cáo xoay vòng',
    },
    type: {
      7: 'Quảng cáo thanh bên trên trang chủ',
      8: 'Cửa sổ bật lên Quảng cáo',
      9: 'Trôi nổi Quảng cáo',
      10: 'Quảng cáo xoay vòng trên trang chủ',
    },
    status: {
      0: 'Chưa gửi để xem xét',
      1: 'đang được xem xét',
      2: 'Phê duyệt',
      3: 'Sự suy sụp',
      4: 'Đóng danh sách',
      5: 'Tất cả',
    },
  },
  plan_bonus: {
    category: {
      1: 'Cập nhật sắp xếp',
      2: 'Dịch vụ đăng tin',
      3: 'Dịch vụ dán nhãn',
    },
    type: {
      1: {
        1: 'Cập nhật thủ công',
        2: 'Cập nhật tự động',
        3: 'Đặt thời gian cập nhật',
      },
      2: {
        1: 'Ưu tiên',
        2: 'Đề xuất',
      },
      3: {
        1: 'Ghim đầu trang',
        2: 'Nổi bật',
        3: 'Gấp',
      },
    },
    status: {
      0: 'Vô hiệu hóa',
      1: 'Cho phép',
    },
  },
  plan_lessor: {
    type: {
      1: 'Đăng tin cho thuê',
      2: 'Đăng tin mua bán',
      3: 'Đăng tin cho thuê đơn',
      4: 'Đăng tin mua bán đơn',
    },
    status: {
      0: 'Vô hiệu hóa',
      1: 'Cho phép',
    },
  },
  plan_premium: {
    type: {
      5: 'Đăng tin',
      6: 'Môi giới',
    },
    status: {
      0: 'Vô hiệu hóa',
      1: 'Cho phép',
    },
  },
  rating: {
    type: {
      1: 'Chủ nhà/người đại diện',
      2: 'Cửa hàng',
    },
  },
  store: {
    status: {
      0: 'Vô hiệu hóa',
      1: 'Cho phép',
    },
  },
  publish_house_sort: {
    type: {
      1: 'Thời gian phát hành (muộn nhất)',
      2: 'Thời gian phát hành (sớm nhất)',
      3: 'Thời gian lập (muộn nhất)',
      4: 'Thời gian lập (sớm nhất)',
    },
  },
  publish_type: {
    type: {
      1: 'Cho thuê',
      2: 'Mua bán',
      3: 'Cho thuê',
      4: 'Mua bán',
      5: 'Cho thuê',
      6: 'Mua bán',
      7: 'Cho thuê',
      8: 'Mua bán',
      9: 'Cho thuê',
      10: 'Mua bán',
    },
  },
  activity_type: {
    type: {
      1: 'Trung gian môi giới',
      2: 'Khuyến mãi Facebook',
      3: 'Khuyến mãi Zola',
      4: 'Blogger/KOL',
    },
  },
  date_select: {
    1: 'Hôm nay',
    2: 'Tuần này',
    3: 'Tháng này',
    4: 'Tất cả',
  },
  task_status: {
    1: 'Hoàn tác',
    2: 'Trong tiến trình',
    3: 'Hoàn thành',
    4: 'Hủy bỏ',
  },
  review_status: {
    1: 'Chưa được xem xét',
    2: 'Thành công',
    3: 'Từ chối',
  },
  branch_reply_status: {
    3: 'Chưa trả lời',
    4: 'Đồng ý',
    5: 'Từ chối',
  },
  owner_type: {
    1: 'Người bán',
    2: 'Thợ xây',
    3: 'Trung gian mua bán',
  },
  appointment_type: {
    0: 'Riêng biệt',
    1: 'Hợp đồng miệng',
    2: 'Hợp đồng thông thường',
    3: 'Hợp đồng trung gian',
  },
  task_type: {
    1: 'Chuyến thăm cá nhân',
    2: 'Điện thoại',
    3: 'Thăm nhà',
    4: 'Ký hợp đồng',
  },
  contract: {
    0: 'Hợp đồng chưa được xem xét',
    1: 'Hợp đồng đã được phê duyệt',
    2: 'Hợp đồng không được phê duyệt',
  },
  position: {
    1: 'Quản lý',
    2: 'Giám đốc',
    3: 'Phó giám đốc',
    4: 'Chủ quản ',
    5: 'Tổ trưởng',
    6: 'Nghiệp vụ',
  },
  client_tabs: {
    0: 'Tất cả',
    1: 'Gần đây',
    2: 'Không ghé thăm trong hai tuần',
  },
  assignment_status: {
    0: 'Chưa được chỉ định',
    1: 'Đã được chỉ định',
  },
  contact_record: {
    1: 'Điện thoại',
    2: 'Zalo',
  },
  business_notification: {
    0: 'Tất cả',
    1: 'Tóm tắt nhiệm vụ',
    2: 'Ủy nhiệm kiểm duyệt',
    3: 'Nhánh',
    4: 'Thăm nom',
    5: 'Kiểm tra trong',
    6: 'Trả lại',
    7: 'Thư ký chỉ định',
  },
  placeholder: {
    minimumPrice: 'Từ',
    maximumPrice: 'Đến',
    minimumArae: 'Từ',
    maximumArae: 'Đến',
    minimumFloor: 'Từ',
    maximumFloor: 'Đến',
    searchId: 'Vui lòng nhập số',
    contractAmount: 'Vui lòng nhập số tiền phí dịch vụ',
    notApproved: 'Vui lòng nhập lý do thất bại',
    selectAnAccount: 'Vui lòng chọn một tài khoản',
    selectIdentity: 'Vui lòng chọn danh tính để đăng tin',
  },
  alertText: {
    phone: 'Vui lòng nhập số điện thoại',
    pwd: {
      error: 'Vui lòng nhập mật khẩu từ 8 đến 12 ký tự bao gồm các ký tự chữ và số',
      over: 'Mật khẩu vượt quá 12 kí tự',
      noMatch: 'Mật khẩu không trùng khớp',
    },
    point: 'Nếu không đủ điểm sẽ được chuyển đến phần quản lý điểm.',
    publishPriceErr: 'Số tiền tối thiểu không ít hơn 23000 nhân dân tệ',
    publishDayErr: 'Số ngày không thể nhỏ hơn 0',
    areaErr: 'Vui lòng chọn ít nhất một',
    logoutFail: 'Đăng xuất không thành công',
    planRoleNotPermit: 'Danh tính tài khoản hiện tại của bạn không thể sử dụng giải pháp này, vui lòng chuyển sang danh tính khác và thử',
    loginFirst: 'Vui lòng đăng nhập trước',
    pleaseCheck: 'Hãy đánh dấu',
    over50mb: 'Tệp tải lên không được vượt quá 50 MB',
    over25Pics: 'Có thể tải lên tối đa 25 ảnh, vui lòng chọn lại',
    maxPic: 'Đã đạt đến số lượng tải lên tối đa',
    certType: 'Vui lòng chọn loại chứng chỉ',
    country: 'Vui lòng chọn tỉnh, quận, thành phố',
    area: 'Hãy chọn một vùng',
    companyName: 'Vui lòng điền tên công ty',
    branch: 'Hãy điền tên chi nhánh',
    flyersEmpty: 'Vui lòng đánh dấu vào những ngôi nhà cần làm tờ rơi',
    selectRating: 'Vui lòng chọn đánh giá',
    downloadFlyers: ' Để tải xuống tờ rơi, vui lòng vào ghi chép dữ liệu ',
    rechargePoints: 'Vui lòng nhập điểm nạp tiền',
  },
  noticeText: {
    loginAgain: 'Vui lòng đăng nhập lại',
    addFavorite: 'Đã thêm vào mục yêu thích',
    removeFavorite: 'Đã xóa khỏi mục yêu thích',
    loginSuccess: 'Đăng nhập thành công',
    logoutSuccess: 'Đăng xuất thành công',
    modifiedSuccessfully: 'Đã sửa đổi thành công',
    storeEnabled: 'Cửa hàng đã được kích hoạt',
    storeClosed: ' Cửa hàng đã đóng ',
    question: 'Câu hỏi đã được gửi',
    closeHouseSuccess: 'Đã đóng nhà thành công',
    dealHouseSuccess: 'Đã giao dịch thành công',
    sendSuccess: 'Đã gửi thành công',
    updateSortSuccess: 'Đã cập nhật thành công',
    buySuccess: 'Đã mua thành công',
    remittanceSuccess: 'Sau khi mua hàng thành công, vui lòng đến phần quản lý giao dịch để điền thông tin chuyển tiền sau khi chuyển tiền để xác nhận xem điểm đã được chuyển chưa.',
    addSubAccountSuccess: 'Thêm tài khoản phụ thành công.',
    accountIsEnabled: 'Tài khoản đã kích hoạt',
    accountIsDisabled: 'Tài khoản đã ngừng hoạt động',
    deleteMsgSuceess: 'Tin nhắn đã được xóa',
    sendMsgSuccess: 'Đã gửi trả lời',
    reportSuccess: 'Báo cáo đã được gửi thành công',
    openingSoon: 'Sắp mở cửa',
    certificationSuccess: 'Gửi thành công, vui lòng đợi xét duyệt trong vòng 24h',
    flyersSuccess: 'Làm tờ rơi thành công',
    evaluationSuccess: 'Đánh giá đã được gửi',
    copiedSuccess: 'Đã sao chép thành công',
    advertisementSuccess: 'Ứng dụng quảng cáo hình ảnh thành công, thời gian xem xét khoảng 1-3 ngày làm việc, nếu có thắc mắc vui lòng liên hệ bộ phận chăm sóc khách hàng.',
    publishArticleSuccess: 'Bài viết được đăng thành công',
    deleteArticleSuceess: 'Bài viết đã bị xóa',
    modifyReplySuccess: 'Trả lời đã sửa đổi thành công',
    deleteReplySuceess: 'Trả lời đã bị xóa',
    subSuccess: 'Thành công',
    verficationCodeSuccess: 'Đã gửi mã xác minh',
    saveSuccess: 'Đã thêm thành công',
    subReportSuccess: 'Báo cáo thành công',
    deleteTaskSuccess: 'Đã xóa thành công',
    auditSuccess: 'Xét duyệt thành công',
    openSubscriptionSuccess: 'Tự động gia hạn được kích hoạt',
    closeSubscriptionSuccess: 'Tự động gia hạn bị tắt',
    checkInSuccess: 'Kiểm tra thành công',
    uploadSuccess: 'Tải lên thành công',
  },
  success: {
    save: 'đã lưu thành công',
    purchasePlane: 'Nếu việc mua hàng thành công, bạn sẽ được chuyển đến phần quản lý giải pháp.',
  },
  noDataText: {
    noData: 'Tạm thời chưa có thông tin',
    noMsg: 'Không có tin nhắn',
    noComments: 'Chưa có bình luận nào',
    noLandMark: 'Không có địa danh nào gần đó',
    noPromotions: 'Không có khuyến mãi',
    noRecord: 'Không có lịch sử xem',
    noClient: 'Thông tin khách hàng không thu được',
  },
  popularArea: [
    {
      name: 'Hồ Chí Minh',
      en: 'Ho Chi Minh City',
    },
    {
      name: 'Hà Nội',
      en: 'Ha Noi City',
    },
    {
      name: 'Hải Phòng',
      en: 'Hai Phong City',
    },
    {
      name: 'Cần Thơ',
      en: 'Can Tho City',
    },
    {
      name: 'Đà Nẵng',
      en: 'Da Nang City',
    },
    {
      name: 'Đồng Nai',
      en: 'Dong Nai Province',
    },
    {
      name: 'Bình Dương',
      en: 'Binh Duong Province',
    },
  ],
};

export default vn;
